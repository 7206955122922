export default function ChevronUp({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.29297 13.7031L7.70697 15.1171L12 10.8241L16.293 15.1171L17.707 13.7031L12 7.99609L6.29297 13.7031Z"
        fill="currentColor"
      />
    </svg>
  );
}
