/* eslint-disable max-len */
export default function StarIcon({ className }: { className?: string }) {
  return (
    <svg
      className={`${className}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2625 10.9057C29.1787 10.6588 29.0245 10.4419 28.8188 10.2818C28.6131 10.1216 28.3651 10.0251 28.1052 10.0043L20.5039 9.40034L17.2145 2.11901C17.1098 1.88449 16.9394 1.68531 16.7239 1.54549C16.5085 1.40567 16.2572 1.3312 16.0003 1.33105C15.7435 1.33091 15.4921 1.40511 15.2764 1.54469C15.0608 1.68428 14.8902 1.88328 14.7852 2.11768L11.4959 9.40034L3.89454 10.0043C3.63915 10.0246 3.39499 10.118 3.19128 10.2733C2.98757 10.4287 2.83293 10.6394 2.74586 10.8804C2.6588 11.1213 2.64299 11.3823 2.70033 11.6319C2.75768 11.8816 2.88574 12.1095 3.06921 12.2883L8.68654 17.7643L6.69987 26.367C6.63955 26.6274 6.65888 26.8999 6.75537 27.1492C6.85185 27.3985 7.02102 27.613 7.24094 27.7649C7.46086 27.9169 7.72136 27.9992 7.98865 28.0012C8.25594 28.0033 8.51767 27.9249 8.73987 27.7763L15.9999 22.9363L23.2599 27.7763C23.487 27.9271 23.7548 28.0048 24.0274 27.9988C24.2999 27.9928 24.5641 27.9035 24.7844 27.7429C25.0047 27.5823 25.1705 27.3581 25.2596 27.1004C25.3486 26.8428 25.3566 26.564 25.2825 26.3017L22.8439 17.7683L28.8919 12.3257C29.2879 11.9683 29.4332 11.411 29.2625 10.9057Z"
        fill="currentColor"
      />
    </svg>
  );
}
