import ArrowDown from '@opulous/web/src/components/Icons/ArrowDown';
import { MENU_ITEMS_V3 } from '@opulous/web/src/lib/constants';
import { useEffect, useMemo, useState } from 'react';
import style from './HeaderMenus.module.css';
import { useLocation } from 'react-router';
import InfoIcon from '@opulous/web/src/components/Icons/Info';

const menuItems = MENU_ITEMS_V3;

// TODO: FIX DROPDOWN POSITION, DARK MODE, MOBILE VIEW
const HeaderMenus: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<number | null>(null);
  const [delayedActiveMenu, setDelayedActiveMenu] = useState<number | null>(null);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    setTimeout(() => {
      setDelayedActiveMenu(activeMenu);
    }, 10);
  }, [activeMenu])

  const toggleActiveMenu = (key: number) => {
    setActiveMenu(key === activeMenu ? null : key);
  };

  const isNoTheme = useMemo(() => {
    if(pathname === '/ai') return true
    return false
  }, [pathname])

  const isHighlightMenu = (key: number) => {
    if(key === 3 && (pathname === '/mfts' || pathname === '/assets')) {
      return true
    }
  }

  const openExternalLink = (href: string) => {
    window.location.href = href;
  }

  return (
    <div className="flex items-center relative w-full justify-center gap-3">
      {menuItems(isNoTheme).map((menu, key) => (
        <div className="flex items-center relative" key={`header-menu-${key}`}>
          {/* start: button */}
          <button
            className={[
              'flex gap-2 items-center justify-center pe-2 py-0 rounded-lg',
              isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-white dark:hover:bg-surfaces-001',
              (menu.items.length === 0 || (menu.items.length > 0 && activeMenu !== key))  && (isNoTheme ? style['aiNavItem'] : style['navItem']),
              (pathname === menu.url || isHighlightMenu(key)) && (isNoTheme ? style['aiNavItemActive'] : style['navItemActive']),
              activeMenu === key ? (isNoTheme ? 'bg-[#FFFFFF0D]' : 'bg-white dark:bg-surfaces-001') : 'bg-transparent',
            ].join(' ')}
            onClick={() => menu.items.length > 0 ? toggleActiveMenu(key) : openExternalLink(menu?.url || '')}
            onMouseOver={() => menu.items.length > 0 && setActiveMenu(key)}
            onMouseLeave={() => menu.items.length > 0 && setActiveMenu(null)}
          >
            <span
              className={[
                'font-normal leading-6 text-base flex items-center justify-center',
                activeMenu === key || isHighlightMenu(key) ? (isNoTheme ? 'text-white' : 'text-primary-001') : (isNoTheme ? 'text-white' : 'text-surfaces-003  dark:text-white'),
              ].join(' ')}
            >
              <span className={`flex items-center justify-center flex-shrink-0 h-9 w-9`}>
                {menu.icon ? menu.icon : <></>}
              </span>
              {menu.name}
            </span>
            {
              menu.items.length > 0 && 
              <ArrowDown
                className={[
                  'flex flex-shrink-0 h-5 w-5 transition-transform duration-300',
                  activeMenu === key
                    ? (isNoTheme ? 'text-white rotate-180' : 'text-primary-001 rotate-180')
                    : (isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'),
                    isHighlightMenu(key) && '!text-primary-001',
                ].join(' ')}
              />
            }
          </button>
          {/* end: button */}
        </div>
      ))}
      {/*  top-[calc(100%+1.75rem)] */}
      {/* start: dropdown */}
      {delayedActiveMenu !== null ? (
        <div
          className="absolute right-0 flex items-end justify-end top-full w-fit z-[999]"
          onMouseOver={() => setActiveMenu(delayedActiveMenu)}
          onMouseLeave={() => setActiveMenu(null)}
        >
          <div
            className={`${isNoTheme ? 'bg-[linear-gradient(90.41deg,_#00000085_0%,_#00000000_100%)] !backdrop-blur-lg border-[0.5px] border-[#363738]' : 'bg-white dark:bg-surfaces-001 border-surfaces-012 dark:border-[#16254a]'} border border-solid grid grid-cols-1 mt-[1.75rem] p-4 rounded-2xl gap-2 w-fit max-w-[21.0625rem] ${style.menuDropdown}`}
          >
            <div className="bg-secondary-009 dark:bg-dark-secondary-009 border border-solid border-secondary-007 dark:border-[#8c471e] flex items-center gap-2 p-3 rounded-xl w-full">
              <div className="flex flex-shrink-0 h-6 w-6">
                <InfoIcon className="h-full text-primary-001 w-full" />
              </div>
              <p className="flex font-normal leading-[1.125rem] text-primary-001 text-xs">
                <span>
                  We{`'`}re switching from Algorand to Arbitrum. Head over to our <a className="font-bold underline" href="/migration">migration page</a> and follow the instructions.</span>
              </p>
            </div>
            {menuItems(isNoTheme)[delayedActiveMenu].items.map((item, i) => (
              <a
                href={item.url}
                onClick={() => setActiveMenu(null)}
                key={`header-menu-dropdown-${i}`}
                className={`
                ${pathname === item.url
                  ? isNoTheme
                    ? 'bg-[#FFFFFF0D]'
                    : 'bg-surfaces-011 dark:bg-[#2D2F38]'
                  : 'bg-transparent'} ${isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-surfaces-011 hover:dark:bg-[#2D2F38] border-[0.0938rem] border-surfaces-012 dark:border-dark-surfaces-012'} rounded-2xl flex items-center gap-3 p-4`}
                target={item.isOpenNewTab ? "_blank" : undefined}
                rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
              >
                <span className={`${isNoTheme ? 'bg-[rgba(255,255,255,0.1)]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-12 rounded-[0.75rem] w-12`}>
                  {item.icon ? item.icon : <></>}
                </span>
                <span className="flex flex-col gap-[0.125rem]">
                  <span className={`flex font-medium leading-[1.6875rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                    {item.name}
                  </span>
                  {item.text ? (
                    <span className={`flex font-light leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-004 dark:text-[#999daa]'} text-xs`}>
                      {item.text}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </a>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* end: dropdown */}
    </div>
  );
};

export default HeaderMenus;