import MoonIcon from '@opulous/web/src/components/Icons/moon';
import SunIcon from '@opulous/web/src/components/Icons/sun';
import { useContext } from 'react';
import ThemeColorModeContext from 'src/context/ThemeColorModeContext';

const ThemeSwitcher: React.FC = () => {
  const themeColorModeContext = useContext(ThemeColorModeContext);
  // const { resolvedTheme, setTheme } = useTheme();

  // const toggleTheme = () => {
  //   setTheme(resolvedTheme === 'light' ? 'dark' : 'light');
  // };

  // useEffect(() => {
  //   // set theme state
  //   if (mounted && resolvedTheme) {
  //     dispatch(setThemeState(resolvedTheme));
  //   }
  // }, [dispatch, mounted, resolvedTheme]);

  return (
    <div className="flex items-center gap-[0.375rem]">
      <span className="flex flex-shrink-0 h-[0.875rem] w-[0.875rem]">
        <SunIcon className="h-full text-surfaces-003 dark:text-surfaces-006 w-full" />
      </span>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value=""
          checked={themeColorModeContext.mode === 'dark'}
          className="sr-only peer"
          onChange={themeColorModeContext.toggle}
        />
        <div
          className={[
            'w-8 h-5',
            'bg-surfaces-007 peer-checked:bg-[#3b3e48]',
            'rounded-full peer-checked:border-neutral-800',
            'peer peer-checked:after:translate-x-[0.75rem] after:rounded-full',
            'after:absolute after:top-[0.0625rem] after:left-[0.0625rem] after:bg-surfaces-011',
            'after:h-4.5 after:w-4.5 after:transition-all ease-in-out duration-300',
          ].join(' ')}
        />
      </label>
      <span className="flex flex-shrink-0 h-[0.875rem] w-[0.875rem]">
        <MoonIcon className="h-full text-surfaces-007 dark:text-white w-full" />
      </span>
    </div>
  );
};

export default ThemeSwitcher;
