/* eslint-disable max-len */
export default function SunIcon({ className }: { className?: string }) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.9987 9.99992C6.07044 9.99992 5.1802 9.63117 4.52382 8.97479C3.86745 8.31842 3.4987 7.42818 3.4987 6.49992C3.4987 5.57166 3.86745 4.68142 4.52382 4.02505C5.1802 3.36867 6.07044 2.99992 6.9987 2.99992C7.92696 2.99992 8.81719 3.36867 9.47357 4.02505C10.13 4.68142 10.4987 5.57166 10.4987 6.49992C10.4987 7.42818 10.13 8.31842 9.47357 8.97479C8.81719 9.63117 7.92696 9.99992 6.9987 9.99992ZM6.41537 0.083252H7.58203V1.83325H6.41537V0.083252ZM6.41537 11.1666H7.58203V12.9166H6.41537V11.1666ZM2.04911 2.37517L2.87395 1.55034L4.1112 2.78759L3.28636 3.61242L2.04911 2.37575V2.37517ZM9.8862 10.2123L10.711 9.38742L11.9483 10.6247L11.1234 11.4495L9.8862 10.2123ZM11.1234 1.54975L11.9483 2.37517L10.711 3.61242L9.8862 2.78759L11.1234 1.55034V1.54975ZM3.28636 9.38742L4.1112 10.2123L2.87395 11.4495L2.04911 10.6247L3.28636 9.38742ZM13.4154 5.91659V7.08325H11.6654V5.91659H13.4154ZM2.33203 5.91659V7.08325H0.582031V5.91659H2.33203Z"
        fill="currentColor"
      />
    </svg>
  );
}
