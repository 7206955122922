interface Props {
  className?: string;
}

const AmbassadorIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className = { className }
    >
      <g clipPath="url(#clip0_4998_161865)">
        <mask
          id="mask0_4998_161865"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="21"
        >
          <path d="M0.75 0.59082H20.75V20.5908H0.75V0.59082Z" fill="white" />
        </mask>
        <g mask="url(#mask0_4998_161865)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.75 0.59082C16.273 0.59082 20.75 5.06787 20.75 10.5908C20.75 16.1133 16.273 20.5908 10.75 20.5908C5.22705 20.5908 0.75 16.1133 0.75 10.5908C0.75 5.06787 5.22705 0.59082 10.75 0.59082Z"
            fill="#0A0A0A"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1227 3.9248L4.91699 16.4248H7.05627L12.7984 6.46378L13.4856 9.04642L9.23035 16.4248H11.4041L14.172 11.6256L15.4491 16.4248H17.3658L15.4835 9.35146L16.838 7.00297H14.8586L14.0395 3.9248H12.1227Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4998_161865">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.75 0.59082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmbassadorIcon;
