/* eslint-disable max-len */
export default function CopyIcon({ className }: { className?: string }) {
    return (
      <svg
        className={`${className}`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6667 1.66667H8.33333C7.41417 1.66667 6.66667 2.41417 6.66667 3.33333V6.66667H3.33333C2.41417 6.66667 1.66667 7.41417 1.66667 8.33333V16.6667C1.66667 17.5858 2.41417 18.3333 3.33333 18.3333H11.6667C12.5858 18.3333 13.3333 17.5858 13.3333 16.6667V13.3333H16.6667C17.5858 13.3333 18.3333 12.5858 18.3333 11.6667V3.33333C18.3333 2.41417 17.5858 1.66667 16.6667 1.66667ZM3.33333 16.6667V8.33333H11.6667L11.6683 16.6667H3.33333ZM16.6667 11.6667H13.3333V8.33333C13.3333 7.41417 12.5858 6.66667 11.6667 6.66667H8.33333V3.33333H16.6667V11.6667Z"
          fill="currentColor"
        />
      </svg>
    );
  }
  