import React from 'react'
import './index.css';
import Migration from '@opulous/web/src/components/MigrationPage'

const MigrationPage: React.FC = () => {
  return (
    <div className="text-surfaces-003 dark:text-white font-normal leading-[1.5]">
      <Migration />
    </div>
  )
}

export default MigrationPage;
