/* eslint-disable max-len */
export default function Close({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.192 6.84375L11.949 11.0858L7.70697 6.84375L6.29297 8.25775L10.535 12.4998L6.29297 16.7418L7.70697 18.1558L11.949 13.9137L16.192 18.1558L17.606 16.7418L13.364 12.4998L17.606 8.25775L16.192 6.84375Z"
        fill="currentColor"
      />
    </svg>
  );
}
