import { SIDE_MENU_V3 } from '@opulous/web/src/lib/constants';
import { useLocation } from 'react-router';
import { useEffect, useMemo } from 'react';

interface Props {
  onCloseMenu: () => void;
  showMobileMenu: boolean;
}

const MobileMenus: React.FC<Props> = ({ onCloseMenu, showMobileMenu }: Props) => {
  // const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const location = useLocation();
  const pathname = location.pathname;
  const isNoTheme = useMemo(() => {
    if(pathname === '/ai') return true
    return false
  }, [pathname])

  const menuItems = useMemo(() => {
    return SIDE_MENU_V3(isNoTheme)
  }, [isNoTheme])

  const scrollEnabled = useMemo(() => {
    return !showMobileMenu
  }, [showMobileMenu])

  useEffect(() => {
    document.body.style.overflow = scrollEnabled ? 'auto' : 'hidden';
    if(scrollEnabled)
      document.body.classList.add('!overflow-y-auto')
    else 
      document.body.classList.remove('!overflow-y-auto')
    return () => {
      // Cleanup
      document.body.style.overflow = 'auto';
    };
  }, [scrollEnabled]);

  return (
    <div className={`${isNoTheme ? 'bg-[linear-gradient(90.41deg,_#00000085_0%,_#00000000_100%)] !backdrop-blur-lg border-[0.5px] border-[#363738]' : 'bg-surfaces-012 dark:bg-[#2f323c]'} fixed flex flex-col gap-8 h-[calc(100%-5.313rem)] left-0 p-5 top-[5.313rem] w-full z-50 overflow-auto`}>
      {menuItems.map((menu, key) => (
        <div className="flex flex-col gap-3" key={`mobile-menu-${key}`}>
          <button
            className="flex items-center justify-between outline-0 focus:outline-0 w-full"
            // onClick={() => {
            //   setActiveDropdown(activeDropdown === key ? null : key);
            // }}
          >
            <span className={`flex font-normal leading-[1.6875rem] text-lg  ${isNoTheme ? 'text-white' : 'text-surface-003 dark:text-white'}`}>
              {menu.name}
            </span>
            {/* <ArrowDown
              className={[
                'flex flex-shrink-0 h-6 w-6 transition-transform duration-300',
                activeDropdown === key ? 'rotate-180' : '',
                isNoTheme ? 'text-white' : 'text-surface-003 dark:text-white',
              ].join(' ')}
            /> */}
          </button>

          {(
            <div className="flex flex-col gap-4 w-full">
              {key !== 1 && menu.items.map((item, i) => (
                <a
                  className="flex gap-3 items-center w-full"
                  href={item.url}
                  key={`mobile-menu-${key}-item-${i}`}
                  target={item.isOpenNewTab ? "_blank" : undefined}
                  rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                  onClick={onCloseMenu}
                >
                  <span className={`${isNoTheme ? 'bg-[#FFFFFF1A]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-9 rounded w-9`}>
                    {item.icon ? item.icon : <></>}
                  </span>

                  <span className="flex flex-col w-full">
                    <span className={`flex font-medium leading-[1.3125rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                      {item.name}
                    </span>
                    <span className={`flex leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-005 dark:text-[#999daa]'} text-xs`}>
                      {item.text}
                    </span>
                  </span>
                </a>
              ))}
            </div>
          )}

          {(
            key === 1 && <>
              <div className="flex flex-col gap-4 w-full">
                {menu.items.slice(0, 3).map((item, i) => (
                  <a
                    className="flex gap-3 items-center w-full"
                    href={item.url}
                    key={`mobile-menu-${key}-item-${i}`}
                    target={item.isOpenNewTab ? "_blank" : undefined}
                    rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                    onClick={onCloseMenu}
                  >
                    <span className={`${isNoTheme ? 'bg-[#FFFFFF1A]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-9 rounded w-9`}>
                      {item.icon ? item.icon : <></>}
                    </span>

                    <span className="flex flex-col w-full">
                      <span className={`flex font-medium leading-[1.3125rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                        {item.name}
                      </span>
                      <span className={`flex leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-005 dark:text-[#999daa]'} text-xs`}>
                        {item.text}
                      </span>
                    </span>
                  </a>
                ))}
              </div>
              <div className="flex gap-2">
              {menu.items.slice(3, menu.items.length).map((item, i) => (
                  <a
                    className="flex items-center w-full"
                    href={item.url}
                    key={`mobile-menu-${key}-item-${i}`}
                    target={item.isOpenNewTab ? "_blank" : undefined}
                    rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                    onClick={onCloseMenu}
                  >
                    <span className={`w-full ${isNoTheme ? 'bg-[#FFFFFF1A]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-[2.125rem] rounded w-[6.6456rem]`}>
                      {item.icon ? item.icon : <></>}
                    </span>
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileMenus;