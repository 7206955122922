import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from '@opulous/web/src/config';
import * as Sentry from '@sentry/react';
import { AppBar, Box, Button, Container, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '@opulous/web/src/assets/Logo.svg';
import WalletContext from '@opulous/web/src/context/context';
import WalletDropDownMenu from './WalletDropDownMenu';
import TabLinklist from './TabMenuList';
import MobileLogo from '@opulous/web/src/assets/Logo_mobile.svg';
import Notifications from './Notifications';
import { myAlgo } from '@opulous/web/src/services/';
import assetsService from '@opulous/web/src/services/assets';
import { Asset, WalletTypeEnum } from '@opulous/web/src/shared/types';
import ThemeColorModeContext, {
  ThemeMode,
} from '@opulous/web/src/context/ThemeColorModeContext';
import MyAlgoWarningDialog from '@opulous/web/src/components/shared/MyAlgoWarningDialog';
import { DialogConnectWallet } from '@opulous/web/src/components/Common/ConnectWallet/DialogConnectWallet';
import { OpulInfo } from '@opulous/web/src/components/layout/Header/components';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: `${theme.spacing(0)} !important`,
  [theme.breakpoints.down('lg')]: {
    minHeight: theme.spacing(8),
  },
}));

const ConnectedWrapper = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: theme.spacing(3),
}));

const LogoLink = styled('a')(() => ({
  display: 'flex',
}));

export default function ButtonAppBar(): ReactElement {
  const themeColorModeContext = useContext(ThemeColorModeContext);
  const isTablet = useMediaQuery('(max-width: 960px)');
  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(false);
  const [assetCount, setAssetCount] = useState<number>(0);
  const [opulCount, setOpulCount] = useState<number>(0);
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);
  const location = useLocation();

  const {
    state: { wallet, timestamp: walletTimestamp, isWalletConnectDialogOpen },
    disconnectWallet,
    initPeraWallet,
    setIsWalletConnectDialogOpen
  } = useContext(WalletContext);

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        setIsWalletConnectDialogOpen(false);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        setIsWalletConnectDialogOpen(false);
        return;
    }
  };

  const handleConnect = async (type: WalletTypeEnum) => {
    try {
      await connectWallet(type);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const checkAvailableAssets = async () => {
    if (!wallet) {
      return;
    }

    let unclaimedAssets: Asset[] = [];
    try {
      unclaimedAssets =
        (await assetsService.fetchAllAssetsAvailableToClaim(wallet)) || [];
    } catch (ex) {
      Sentry.captureException(ex);
    }

    setAssetCount(unclaimedAssets.length);
  };

  const fetchOpulCount = async () => {
    if (!wallet) {
      return;
    }

    let opulsOwned = 0;
    try {
      opulsOwned = (await myAlgo.getOpulAssetCount(wallet)) || 0;
    } catch (ex) {
      Sentry.captureException(ex);
    }

    setOpulCount(opulsOwned);
  };

  useEffect(() => {
    checkAvailableAssets();
    fetchOpulCount();
  }, [wallet, location, walletTimestamp]);

  const handleDisconnect = () => {
    disconnectWallet();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <StyledToolbar>
            <LogoLink href={config.env.WEB_SITE_ADDRESS} data-testid="logo">
              <img
                alt="Opulous logo"
                width="100%"
                height="auto"
                src={isTablet ? MobileLogo : Logo}
                style={
                  themeColorModeContext.mode === ThemeMode.DARK
                    ? { filter: 'invert(1)' }
                    : {}
                }
              />
            </LogoLink>
            {isTablet && wallet && (
              <IconButton onClick={() => setIsOpenSideBar(true)}>
                <StyledMenuIcon />
              </IconButton>
            )}
            <div style={{ flexGrow: 1 }} />
            <ConnectedWrapper>
              <TabLinklist
                isTablet={isTablet}
                openSidebar={isOpenSideBar}
                onCloseSidebar={() => setIsOpenSideBar(false)}
              />
              {!!wallet && (
                <>
                  <Notifications count={assetCount} />
                  <OpulInfo amount={opulCount} />
                </>
              )}
              {wallet && (
                <WalletDropDownMenu
                  onDisconnect={handleDisconnect}
                  wallet={wallet}
                />
              )}
              {!wallet && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => setIsWalletConnectDialogOpen(true)}
                >
                  Connect Wallet
                </Button>
              )}
            </ConnectedWrapper>
          </StyledToolbar>
        </Container>
      </AppBar>
      <DialogConnectWallet
        onClick={() => setIsWalletConnectDialogOpen(false)}
        onClose={() => setIsWalletConnectDialogOpen(false)}
        onConnect={handleConnect}
        style={{
          opacity: isWalletConnectDialogOpen ? 1 : 0,
          pointerEvents: isWalletConnectDialogOpen ? 'all' : 'none',
        }}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </Box>
  );
}
