/* eslint-disable max-len */
export default function MoonIcon({ className }: { className?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.99725 6.88677C6.26328 6.15251 5.76343 5.21717 5.56087 4.19894C5.3583 3.1807 5.46211 2.12527 5.85917 1.16602C4.72707 1.38888 3.68718 1.94437 2.8725 2.76143C0.594583 5.03935 0.594583 8.73302 2.8725 11.0109C5.151 13.2894 8.84408 13.2888 11.1226 11.0109C11.9394 10.1963 12.4949 9.15669 12.718 8.02485C11.7587 8.42183 10.7033 8.5256 9.6851 8.32303C8.66688 8.12047 7.73154 7.62067 6.99725 6.88677Z"
        fill="currentColor"
      />
    </svg>

    // <svg
    //   className={`${className}`}
    //   width="20"
    //   height="20"
    //   viewBox="0 0 20 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M2.5 14.1667C9.12667 15.5147 13.9024 7.29812 9.16667 2.5C13.3339 2.60953 16.6667 5.92624 16.6667 10C16.6667 14.1429 13.4323 17.5 9.16667 17.5C6.47593 17.5 3.88282 16.1932 2.5 14.1667Z"
    //     stroke="currentColor"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
}
