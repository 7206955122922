interface Props {
  className?: string;
}

const StakeIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="clip0_5841_15656">
        <path
          d="M5.99963 6.49988C9.4335 6.49988 11.9993 5.14241 11.9993 3.92862C11.9993 2.71494 9.43344 1.35742 5.99963 1.35742C2.56582 1.35742 -3.88595e-06 2.71494 -3.88595e-06 3.92862C-3.88595e-06 5.14241 2.56577 6.49988 5.99963 6.49988ZM5.99963 16.7852C6.50083 16.7852 6.98323 16.7561 7.44208 16.703C7.05232 16.2023 6.73387 15.6501 6.4958 15.0619C6.33116 15.0675 6.16594 15.0713 5.99958 15.0713C4.03747 15.0713 2.17932 14.6825 0.767567 13.9766C0.489593 13.8376 0.233794 13.6883 -3.88595e-06 13.5302V14.2141C-3.88595e-06 15.4277 2.56577 16.7852 5.99963 16.7852ZM5.99963 13.3568C6.01724 13.3568 6.03455 13.3565 6.0521 13.3565C6.01649 13.0721 5.99862 12.7857 5.99861 12.4991C5.99861 12.2089 6.01689 11.9229 6.0521 11.6422C6.03455 11.6422 6.01718 11.6428 5.99963 11.6428C4.03753 11.6428 2.17944 11.254 0.767624 10.5481C0.489593 10.4091 0.233736 10.2598 -6.10352e-05 10.1018V10.7856C-3.88595e-06 11.9993 2.56577 13.3568 5.99963 13.3568ZM5.99963 9.92826C6.16971 9.92826 6.3375 9.92483 6.50318 9.91832C6.76754 9.27027 7.12941 8.66645 7.57627 8.12777C7.0527 8.18543 6.52637 8.21434 5.99963 8.21435C4.03753 8.21435 2.17944 7.82557 0.767624 7.11966C0.489593 6.98062 0.233736 6.83134 -6.10352e-05 6.67327V7.35712C-3.88595e-06 8.57085 2.56577 9.92826 5.99963 9.92826ZM8.57135 9.65777C8.19382 10.2246 7.93331 10.8612 7.80509 11.5301C7.74388 11.8494 7.71309 12.1739 7.71314 12.4991C7.71314 12.7481 7.73131 12.9929 7.76566 13.2325C7.8484 13.8099 8.02952 14.3688 8.30115 14.8849C8.5801 15.415 8.94897 15.8927 9.39133 16.2966C10.3062 17.1321 11.5229 17.6425 12.8566 17.6425C15.6927 17.6425 18 15.3351 18 12.4991C18 9.66292 15.6927 7.35563 12.8566 7.35563C12.5634 7.35563 12.276 7.38066 11.9961 7.42798C10.5704 7.66909 9.34235 8.49884 8.57135 9.65777Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5841_15656">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StakeIcon;
