interface Props {
  className?: string;
}

const AmbassadorIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.4107 3.91709C16.4087 3.85258 16.3899 3.78971 16.3562 3.73466C16.3225 3.6796 16.2751 3.63426 16.2185 3.60309C16.162 3.57192 16.0984 3.55599 16.0338 3.55687C15.9693 3.55774 15.9061 3.5754 15.8505 3.60809C15.3465 3.90209 14.562 4.28159 13.8682 4.28159C13.5525 4.28159 13.2315 4.23359 12.9015 4.13309C12.6939 4.07127 12.4913 3.9938 12.2955 3.90134C11.292 3.42209 10.3702 2.57609 9.52345 1.73459C9.38377 1.59693 9.19606 1.51894 8.99995 1.51709C8.80385 1.51894 8.61614 1.59693 8.47645 1.73459C7.6297 2.57609 6.70795 3.42209 5.70445 3.90134C5.50891 3.99391 5.30582 4.07139 5.09845 4.13309C4.76845 4.23359 4.4467 4.28159 4.1317 4.28159C3.43795 4.28159 2.65345 3.90134 2.14945 3.60809C2.0938 3.5754 2.0306 3.55774 1.96607 3.55687C1.90153 3.55599 1.83788 3.57192 1.78136 3.60309C1.72484 3.63426 1.67741 3.6796 1.64372 3.73466C1.61003 3.78971 1.59124 3.85258 1.5892 3.91709C1.54345 5.07059 1.53145 7.54709 2.1052 9.50009C3.15445 13.0723 5.4652 15.4821 8.7877 16.4691L8.8927 16.4998L8.9977 16.4893H8.99995L9.1072 16.4998L9.2122 16.4691C12.5347 15.4821 14.8455 13.0723 15.8947 9.50009C16.4685 7.54709 16.4557 5.07059 16.4107 3.91709Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AmbassadorIcon;
