import AmbassadorIcon from '@opulous/web/src/components/Icons/ambassador';
import AssetIcon from '@opulous/web/src/components/Icons/asset';
import BlogIcon from '@opulous/web/src/components/Icons/blog';
import CartIcon from '@opulous/web/src/components/Icons/cart';
import CollectionIcon from '@opulous/web/src/components/Icons/collection';
// import Discord from '@opulous/web/src/components/Icons/discord';
import FireIcon from '@opulous/web/src/components/Icons/fire';
import Instagram from '@opulous/web/src/components/Icons/instagram';
import Linkedin from '@opulous/web/src/components/Icons/linkedin';
import OpulousBlockIcon from '@opulous/web/src/components/Icons/opulousBlock';
import PeopleIcon from '@opulous/web/src/components/Icons/people';
import QuestionMarkIcon from '@opulous/web/src/components/Icons/questionMark';
import RafflesIcon from '@opulous/web/src/components/Icons/raffles';
import ReleaseMftIcon from '@opulous/web/src/components/Icons/releaseMft';
import RewardIcon from '@opulous/web/src/components/Icons/reward';
import RocketIcon from '@opulous/web/src/components/Icons/rocket';
import SafeIcon from '@opulous/web/src/components/Icons/safe';
import SalesIcon from '@opulous/web/src/components/Icons/sales';
import ScienceSparkIcon from '@opulous/web/src/components/Icons/scienceSpark';
import StakeIcon from '@opulous/web/src/components/Icons/stake';
import Telegram from '@opulous/web/src/components/Icons/telegram';
import TicketSolidIcon from '@opulous/web/src/components/Icons/ticketSolid';
import Twitter from '@opulous/web/src/components/Icons/twitter';
import Youtube from '@opulous/web/src/components/Icons/youtube';

const MENU_ITEMS: (isNoTheme?: boolean) => {
  name: string;
  url?: string;
  items: {
      name: string;
      url: string;
      text: string;
      icon: JSX.Element;
  }[];
}[] = (isNoTheme = false) => [
  // {
  //   name: 'AI',
  //   url: 'https://opulous.org/ai',
  //   items: [],
  // },
  {
    name: 'Finance',
    items: [
      {
        name: 'Tickets',
        url: 'https://opulous.org/tickets',
        text: 'Accumulate tickets and get exclusive access to Opulous products.',
        icon: <TicketSolidIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'OVAULT',
        url: 'https://opulous.org/ovault',
        text: 'Access hand-picked music deals and enjoy consistent rewards.',
        icon: <SafeIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      // {
      //   name: 'AI',
      //   url: 'https://opulous.org/ai',
      //   text: 'Forecast your future music revenue and calculate music advances.',
      //   icon: <ScienceSparkIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      // },
    ],
  },
  {
    name: 'MFT',
    items: [
      {
        name: 'Assets',
        url: 'https://app.opulous.org/assets',
        text: 'View your MFT collection.',
        icon: <AssetIcon fillColor={isNoTheme ? '#000' : '#FCF2EC'} className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Sales',
        url: 'https://app.opulous.org/sales',
        text: "Track your MFTs' performance.",
        icon: <SalesIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Rewards',
        url: 'https://app.opulous.org/rewards',
        text: "Track your MFTs' performance.",
        icon: <RewardIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Raffles',
        url: 'https://app.opulous.org/raffles',
        text: 'Get early access to MFTs and other prizes.',
        icon: <RafflesIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      // { name: 'Exchange', url: 'https://app.opulous.org/' },
    ],
  },
  {
    name: 'OPUL Token',
    items: [
      {
        name: 'Buy',
        url: 'https://opulous.org/buyopul',
        text: 'Use a credit card or trade on an exchange.',
        icon: <CartIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Stake',
        url: 'https://app.opulous.org/pools',
        text: 'Lock your tokens and unlock exclusive benefits.',
        icon: <StakeIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'About OPUL',
        url: 'https://opulous.org/opultoken',
        text: 'Discover everything you need about our token.',
        icon: <OpulousBlockIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
    ],
  },
  {
    name: 'Resources',
    items: [
      {
        name: 'Blog',
        url: 'https://opulous.medium.com/',
        text: 'Stay informed and discover new perspectives.',
        icon: <BlogIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'About Opulous',
        url: 'https://opulous.org/about',
        text: 'Learn about our mission, history, and team.',
        icon: <PeopleIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Help Center',
        url: 'https://intercom.help/opulous/en',
        text: 'Get quick answers to your  questions.',
        icon: <QuestionMarkIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Release an MFT',
        url: 'https://opulous.org/apply',
        text: 'Apply for an MFT sale with Opulous.',
        icon: <ReleaseMftIcon strokeColor={isNoTheme ? '#000' : '#FCF2EC'} className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
    ],
  },
  {
    name: 'Community',
    items: [
      {
        name: 'Ambassadors',
        url: 'https://opulous.medium.com/opulous-announces-ambassador-program-807a97c46c1e',
        text: '',
        icon: <AmbassadorIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Telegram',
        url: 'https://t.me/opulousapp',
        text: '',
        icon: <Telegram className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'X (Twitter)',
        url: 'https://twitter.com/opulousapp',
        text: '',
        icon: <Twitter className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      // {
      //   name: 'Discord',
      //   url: 'https://discord.gg/opulousapp',
      //   text: '',
      //   icon: <Discord className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      // },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/opulousapp/',
        text: '',
        icon: <Instagram className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'Youtube',
        url: 'http://www.youtube.com/c/Opulousapp',
        text: '',
        icon: <Youtube className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/opulous',
        text: '',
        icon: <Linkedin className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      },
    ],
  },
];

const MENU_ITEMS_V3 = (isNoTheme = false) => [
  {
    name: 'AI',
    url: 'https://opulous.org/ai',
    text: 'Forecast your future music revenue and calculate music advances.',
    icon: <ScienceSparkIcon className={`h-[1.75rem] flex flex-shrink-0 xl:h-5 w-[1.75rem] xl:w-5`} />,
    items: []
  },
  {
    name: 'Tickets',
    url: 'https://opulous.org/tickets',
    text: 'Accumulate tickets and get exclusive access to Opulous products.',
    icon: <TicketSolidIcon className={`h-[1.75rem] flex flex-shrink-0 xl:h-5 w-[1.75rem] xl:w-5`} />,
    items: []
  },
  {
    name: 'OVAULT',
    url: 'https://opulous.org/ovault',
    text: 'Access hand-picked music deals and enjoy consistent rewards.',
    icon: <SafeIcon className={`h-[1.75rem] flex flex-shrink-0 xl:h-5 w-[1.75rem] xl:w-5`} />,
    items: []
  },
  {
    name: 'MFTs',
    icon: <CollectionIcon className={`h-[1.75rem] flex flex-shrink-0 xl:h-5 w-[1.75rem] xl:w-5`} />,
    items: [
      {
        name: 'All MFTs',
        url: 'https://opulous.org/mfts',
        text: 'Discover new MFT opportunities.',
        icon: <FireIcon className={`flex flex-shrink-0 h-[1.125rem] xl:h-6 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-[1.125rem] xl:w-5`} />,
      },
      {
        name: 'Assets',
        url: 'https://opulous.org/assets',
        text: 'View your MFT collection.',
        icon: <AssetIcon fillColor={isNoTheme ? '#000' : '#FCF2EC'} className={`flex flex-shrink-0 h-[1.125rem] xl:h-6 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-[1.125rem] xl:w-5`} />,
      },
      {
        name: 'Rewards',
        url: 'https://app.opulous.org/rewards',
        text: "Track your MFTs' performance.",
        icon: <RewardIcon className={`flex flex-shrink-0 h-[1.125rem] xl:h-6 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-[1.125rem] xl:w-5`} />,
        isOpenNewTab: true,
      },
      {
        name: 'Release an MFT',
        url: 'https://opulous.org/apply',
        text: 'Apply for an MFT sale with Opulous.',
        icon: <RocketIcon className={`flex flex-shrink-0 h-[1.125rem] xl:h-6 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-[1.125rem] xl:w-5`} />,
      },
    ],
  },
];

const SIDE_MENU_V3 = (isNoTheme = false) => [
  {
    name: 'OPUL Token',
    items: [
      {
        name: 'Buy OPUL',
        url: 'https://opulous.org/buyopul',
        text: 'Use a credit card or trade on an exchange.',
        icon: <CartIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: false,
      },
      {
        name: 'About OPUL',
        url: 'https://opulous.org/opultoken',
        text: 'Discover everything you need about our token.',
        icon: <OpulousBlockIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: false,
      },
    ],
  },
  {
    name: 'Community',
    items: [
      {
        name: 'Become an Ambassador',
        url: 'https://opulous.org/ambassador',
        text: 'Join our Ambassador Program to enjoy exclusive benefits.',
        icon: <AmbassadorIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: false,
      },
      {
        name: 'Follow us on X',
        url: 'https://twitter.com/opulousapp',
        text: 'Stay updated with our latest news.',
        icon: <Twitter className={`h-5 w-5 xl:h-7 xl:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
        isOpenNewTab: true,
      },
      {
        name: 'Follow us on Telegram',
        url: 'https://t.me/opulousapp',
        text: 'Connect with our community of enthusiasts.',
        icon: <Telegram className={`h-5 w-5 xl:h-7 xl:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
        isOpenNewTab: true,
      },
      // {
      //   name: 'Ambassadors',
      //   url: 'https://opulous.medium.com/opulous-announces-ambassador-program-807a97c46c1e',
      //   text: '',
      //   icon: <AmbassadorIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
      //   isOpenNewTab: true,
      // },

      // {
      //   name: 'Discord',
      //   url: 'https://discord.gg/opulousapp',
      //   text: '',
      //   icon: <Discord className={`h-5 w-5 xl:h-7 xl:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
      //   isOpenNewTab: true,
      // },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/opulousapp/',
        text: '',
        icon: <Instagram className={`h-5 w-5 lg:h-7 lg:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
        isOpenNewTab: true,
      },
      {
        name: 'Youtube',
        url: 'http://www.youtube.com/c/Opulousapp',
        text: '',
        icon: <Youtube className={`h-5 w-5 lg:h-7 lg:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
        isOpenNewTab: true,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/opulous',
        text: '',
        icon: <Linkedin className={`h-5 w-5 lg:h-7 lg:w-7 flex-shrink-0 ${isNoTheme ? 'text-white' : 'text-primary-001'}`} />,
        isOpenNewTab: true,
      },
    ],
  },
  {
    name: 'Resources',
    items: [
      {
        name: 'Algorand OPUL Pool',
        url: 'https://app.opulous.org/pools',
        text: 'Access the Algorand legacy pools.',
        icon: <StakeIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: true,
      },
      {
        name: 'Blog',
        url: 'https://opulous.medium.com/',
        text: 'Stay informed and discover new perspectives.',
        icon: <BlogIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: true,
      },
      {
        name: 'About Opulous',
        url: 'https://opulous.org/about',
        text: 'Learn about our mission, history, and team.',
        icon: <PeopleIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: false,
      },
      {
        name: 'Help Center',
        url: 'https://intercom.help/opulous/en',
        text: 'Get quick answers to your  questions.',
        icon: <QuestionMarkIcon className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`} />,
        isOpenNewTab: true,
      },
      {
        name: 'Release an MFT',
        url: 'https://opulous.org/apply',
        text: 'Apply for an MFT sale with Opulous.',
        icon: (
          <ReleaseMftIcon
            strokeColor={isNoTheme ? '#000' : '#FCF2EC'}
            className={`h-5 ${isNoTheme ? 'text-white' : 'text-primary-001'} w-5`}
          />
        ),
      },
    ],
  }, 
]

export { MENU_ITEMS, MENU_ITEMS_V3, SIDE_MENU_V3 };