/* eslint-disable no-param-reassign */
'use client';

import Spinner from '@opulous/web/src/components/Icons/Spinner';
import { Fragment } from 'react';

export type btnVariants =
  | 'default'
  | 'primary'
  | 'primary-dark'
  | 'outlined'
  | 'outlined-white'
  | 'hover-primary'
  | 'text-underline'
  | 'disabled'
  | 'custom';

interface Props {
  label?: string;
  width?: string;
  padding?: string;
  textColor?: string;
  bgColor?: string;
  radius?: string;
  fontSize?: string;
  fontWeight?: string;
  textTransform?: string;
  leading?: string;
  whitespace?: string;
  border?: string;
  variant?: btnVariants;
  hover?: string;
  id?: string;
  action?: (evt?: any) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export default function Button({
  label,
  width = 'w-auto',
  textColor = 'text-white',
  bgColor = 'bg-pro-black',
  padding = 'px-8 py-3',
  radius = 'rounded-[3.875rem]',
  fontSize = 'text-[1rem] sm:text-[1.125rem]',
  fontWeight = 'font-medium',
  textTransform,
  leading = 'leading-normal',
  whitespace = 'whitespace-nowrap',
  border = 'border border-transparent',
  variant = 'default',
  hover = 'hover:bg-transparent hover:text-pro-black hover:border-pro-black dark:hover:text-white dark:hover:border-white',
  id,
  children,
  action,
  className,
  disabled = false,
  loading = false,
  loadingText,
  onMouseOver,
  onMouseOut,
  type = 'button',
}: Props) {
  if (disabled) {
    hover = '';
    switch (variant) {
      case 'outlined':
        bgColor = 'bg-transparent';
        textColor = 'text-neutral-950 opacity-30 dark:text-neutral-100';
        border = 'border-none';
        break;
      case 'outlined-white':
        bgColor = 'bg-transparent';
        textColor = 'opacity-30 text-neutral-100';
        border = 'border-none';
        break;
      case 'text-underline':
        padding = 'pb-1 my-3';
        bgColor = '';
        textColor = 'text-neutral-100';
        border = 'border-b border-neutral-100';
        radius = 'rounded-none';
        break;
      case 'primary':
        bgColor = 'bg-primary-primary opacity-30';
        leading = 'leading-[1.3]';
        break;
      case 'primary-dark':
        bgColor = 'bg-primary-primary opacity-30';
        leading = 'leading-[1.3]';
        break;
      case 'disabled':
        bgColor = 'bg-neutral-100';
        textColor = 'text-black';
        break;
      default:
        bgColor = 'bg-neutral-100';
        textColor = 'text-white';
        break;
    }
  } else {
    switch (variant) {
      case 'text-underline':
        padding = 'pb-1 my-3';
        bgColor = '';
        textColor = 'text-pro-black dark:text-white';
        border = 'border-b border-pro-black dark:border-white';
        radius = 'rounded-none';
        hover = 'hover:border-transparent dark:hover:border-transparent';
        break;
      case 'primary':
        bgColor = 'bg-primary-primary';
        leading = 'leading-[1.3]';
        hover = 'hover:bg-[#E45A0C] focus:bg-[#EB986E] active:bg-[#EB986E]';
        break;
      case 'primary-dark':
        bgColor = 'bg-primary-primary';
        leading = 'leading-[1.3]';
        hover =
          'hover:bg-white hover:text-surfaces-001 focus:bg-white focus:text-primary-primary active:text-primary-primary';
        break;
      case 'outlined':
        bgColor = 'bg-transparent';
        textColor = 'text-pro-black dark:text-white';
        border = 'border-2 border-pro-black dark:border-dark-surfaces-004';
        hover =
          'hover:bg-pro-black hover:text-white dark:hover:bg-primary-primary dark:hover:text-white dark:hover:border-primary-primary';
        break;
      case 'outlined-white':
        bgColor = 'bg-transparent';
        textColor = 'text-white';
        border = 'border-2 border-white';
        hover = 'hover:bg-surfaces-003';
        break;
      case 'hover-primary':
        hover = 'hover:bg-primary-primary hover:text-black';
        break;
    }
  }

  if (loading) {
    hover = '';
  }

  return (
    <Fragment>
      <button
        id={id}
        className={`
          ${padding} ${radius} ${fontSize} ${fontWeight} ${textTransform} ${leading} ${whitespace}
          ${bgColor} ${textColor} ${width} ${border} ${hover}
          ${className} text-center
          relative
          disabled:!opacity-100 disabled:bg-surfaces-009 disabled:text-surfaces-005
        `}
        disabled={disabled || loading}
        onClick={action}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        type={type}
      >
        {loading && loadingText ? (
          <div className="flex gap-[0.375rem] items-center">
            <Spinner />
            {loadingText}
          </div>
        ) : (
          label || children
        )}
        <div
          className={`
            ${loading && !loadingText ? 'visible opacity-100' : 'invisible opacity-0'}
            transition-all ease-in-out duration-300
            absolute w-full h-full top-0 left-0 ${radius}
            flex flex-col justify-center items-center
            bg-[rgb(51,51,51)]/[.35] dark:bg-[rgb(0,0,0)]/[.65]
            text-white
          `}
        >
          <Spinner />
        </div>
      </button>
    </Fragment>
  );
}
