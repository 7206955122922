/* eslint-disable max-len */
export default function TimerIcon({ className }: { className?: string }) {
  return (
    <svg
      className={`${className}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3332 4H22.6665V6.66667H17.3332V4ZM3.99984 10.6667H9.33317V13.3333H3.99984V10.6667ZM3.99984 21.3333H9.33317V24H3.99984V21.3333ZM2.6665 16H7.9865V18.6667H2.6665V16ZM28.9425 8.94267L27.0572 7.05733L24.7812 9.33333C23.3386 8.46154 21.6853 8.00049 19.9998 8C14.8545 8 10.6665 12.188 10.6665 17.3333C10.6665 22.4787 14.8545 26.6667 19.9998 26.6667C25.1452 26.6667 29.3332 22.4787 29.3332 17.3333C29.3318 14.9947 28.4486 12.7427 26.8598 11.0267L28.9425 8.94267ZM21.3332 18.6667H18.6665V11.944H21.3332V18.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}
