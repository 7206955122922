'use client';

import { ReactNode, useEffect } from 'react';
import Close from '@opulous/web/src/components/Icons/Close';

interface Props {
  title?: string | ReactNode;
  width: string;
  padding?: string;
  className?: string;
  children: ReactNode;
  show?: boolean;
  bgModal?: string;
  onClose?: (e?: any) => void;
  titleClassName?: string;
  titleWrapperClassName?: string;
  overflowVisible?: boolean;
  inserted?: ReactNode;
  bgOverlay?: string;
  itemOverlayClass?: string;
  leftTitleButton?: ReactNode;
  closeIconSize?: string;
  disableCloseButton?: boolean;
  backdropZindex?: string;
  modalZindex?: string;
  modalInnerMaxHeight?: string;
  isFullscreenMobile?: boolean;
}

export default function Modal({
  title,
  children,
  width = 'w-full lg:w-auto',
  padding = 'pb-9 p-4 lg:p-10',
  bgModal = 'bg-white dark:bg-surfaces-001',
  className,
  show,
  onClose,
  titleClassName = 'font-semibold leading-[2.1rem] normal-case text-[1.25rem] md:text-[1.75rem]',
  titleWrapperClassName = 'flex items-center justify-between',
  overflowVisible = false,
  inserted,
  bgOverlay = 'bg-[rgb(51,51,51)]/[.35] dark:bg-[rgb(0,0,0)]/[.65]',
  itemOverlayClass = 'items-end lg:items-center',
  leftTitleButton,
  closeIconSize = 'w-8 h-8',
  disableCloseButton = false,
  backdropZindex = 'z-[1000]',
  modalZindex = 'z-[1001]',
  modalInnerMaxHeight = 'max-h-[100%]',
  isFullscreenMobile = false,
}: Props) {
  useEffect(() => {
    const handleWindowWheel = (e: Event) => {
      // this will disable wheel and touchmove events for elements inside modal.
      // it affects for example input type range behaviour. therefore, we need to
      // put this element to ignoredElements list so that its behaviour is not broken.
      const ignoredElements = ['input[type="range"]'];
      const isIgnoredElement = ignoredElements.some((selector) =>
        (e.target as HTMLElement).matches(selector),
      );

      if (show && !isIgnoredElement) {
        e.preventDefault();
      }
    };
    const handleChildWheel = (e: Event) => {
      e.stopPropagation();
    };

    const bodyRef = Array.from(document.getElementsByTagName('BODY'));
    bodyRef.forEach((item) => {
      item.addEventListener('wheel', handleWindowWheel, { passive: false });
      item.addEventListener('touchmove', handleWindowWheel, { passive: false });
    });

    let modalRef: Element[] = [];
    setTimeout(() => {
      const overflowContents = Array.from(document.getElementsByClassName('overscroll-y-contain'));
      modalRef = overflowContents;
      modalRef.forEach((item) => {
        // only if modal-inner has scroll
        if (item.scrollHeight > item.clientHeight) {
          item.addEventListener('wheel', handleChildWheel);
          item.addEventListener('touchmove', handleChildWheel);
        }
      });
    }, 100);

    return function cleanup() {
      bodyRef.forEach((item) => {
        item.removeEventListener('wheel', handleWindowWheel);
        item.removeEventListener('touchmove', handleWindowWheel);
      });
      modalRef.forEach((item) => {
        item.removeEventListener('wheel', handleChildWheel);
        item.removeEventListener('touchmove', handleChildWheel);
      });
    };
  }, [show]);

  return (
    <div
      className={`
        ${show ? 'visible opacity-100' : 'invisible opacity-0'}
        transition-all ease-in-out duration-300
        w-[100dvw] h-[100dvh] max-w-full fixed top-0 left-0
        flex ${itemOverlayClass} justify-center backdrop-blur-sm ${bgOverlay} ${backdropZindex}
        z-20
      `}
    >
      <div
        className={`
          w-[100dvw] h-[100dvh] max-w-full fixed top-0 left-0 ${backdropZindex}
        `}
        onClick={() => {
          if (disableCloseButton) return;

          onClose && onClose();
        }}
      ></div>

      <div
        className={`
          modal-inner overscroll-y-contain relative
          transition-all ease-in-out duration-300
          ${show ? 'translate-y-0' : 'translate-y-full lg:translate-y-0'}
          ${width} ${padding} mx-auto rounded-t-[24px] lg:rounded-b-[24px]
          shadow-[0_20px_45px_0_rgba(0,0,0,0.05)]
          relative ${modalInnerMaxHeight} ${modalZindex}
          flex flex-col ${bgModal}
          ${overflowVisible ? 'overflow-visible' : 'overflow-y-auto'}
          ${className}
          ${isFullscreenMobile ? 'h-screen xl:h-auto' : ''}
        `}
      >
        {inserted}
        <div className={titleWrapperClassName}>
          {leftTitleButton}
          <h4 className={titleClassName}>{title}</h4>

          <button
            onClick={onClose}
            className="rounded-full transition-all ease-in-out duration-200"
            disabled={disableCloseButton}
          >
            <Close
              className={`${closeIconSize} text-surfaces-300 hover:text-primary-001 dark:text-white dark:hover:text-primary-001`}
            ></Close>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}
