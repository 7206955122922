interface Props {
  className?: string;
}
const CollectionIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8336 8.83342H4.16691C3.24774 8.83342 2.50024 9.58091 2.50024 10.5001V17.1667C2.50024 18.0859 3.24774 18.8334 4.16691 18.8334H15.8336C16.7527 18.8334 17.5002 18.0859 17.5002 17.1667V10.5001C17.5002 9.58091 16.7527 8.83342 15.8336 8.83342ZM4.16691 5.50008H15.8336V7.16675H4.16691V5.50008ZM5.83358 2.16675H14.1669V3.83341H5.83358V2.16675Z" fill={'currentColor'}/>
    </svg>
    
  );
};

export default CollectionIcon;