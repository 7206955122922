interface Props {
  className?: string;
}

const CartIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.8581 5.69919L15.2775 12.0228C15.2169 12.2664 15.0765 12.4827 14.8786 12.6372C14.6807 12.7917 14.4367 12.8754 14.1857 12.875H4.43812C4.16237 12.8751 3.89621 12.7738 3.69024 12.5904C3.48427 12.4071 3.35287 12.1544 3.321 11.8805L2.18194 2.75H1.40625C1.18247 2.75 0.967862 2.66111 0.809629 2.50287C0.651395 2.34464 0.5625 2.13003 0.5625 1.90625C0.5625 1.68247 0.651395 1.46786 0.809629 1.30963C0.967862 1.15139 1.18247 1.0625 1.40625 1.0625H2.91769C3.12158 1.06251 3.31857 1.13635 3.47223 1.27036C3.6259 1.40438 3.72583 1.5895 3.75356 1.7915L4.19344 5H16.3125C16.398 5 16.4824 5.0195 16.5592 5.057C16.6361 5.09451 16.7033 5.14903 16.7559 5.21644C16.8085 5.28385 16.8451 5.36236 16.8628 5.44601C16.8805 5.52966 16.8789 5.61625 16.8581 5.69919ZM3.9375 15.6875C3.9375 16.6179 4.69462 17.375 5.625 17.375C6.55538 17.375 7.3125 16.6179 7.3125 15.6875C7.3125 14.7571 6.55538 14 5.625 14C4.69462 14 3.9375 14.7571 3.9375 15.6875ZM14.0625 15.6875C14.0625 14.7571 13.3054 14 12.375 14C11.4446 14 10.6875 14.7571 10.6875 15.6875C10.6875 16.6179 11.4446 17.375 12.375 17.375C13.3054 17.375 14.0625 16.6179 14.0625 15.6875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CartIcon;
