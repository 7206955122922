interface Props {
  className?: string;
}

const BlogIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5 17.75H4.5C4.30109 17.75 4.11032 17.671 3.96967 17.5303C3.82902 17.3897 3.75 17.1989 3.75 17C3.75 16.8011 3.82902 16.6103 3.96967 16.4697C4.11032 16.329 4.30109 16.25 4.5 16.25H13.5C13.6989 16.25 13.8897 16.329 14.0303 16.4697C14.171 16.6103 14.25 16.8011 14.25 17C14.25 17.1989 14.171 17.3897 14.0303 17.5303C13.8897 17.671 13.6989 17.75 13.5 17.75ZM17.25 5V11C17.25 11.4925 17.153 11.9801 16.9645 12.4351C16.7761 12.89 16.4999 13.3034 16.1517 13.6517C15.8034 13.9999 15.39 14.2761 14.9351 14.4645C14.4801 14.653 13.9925 14.75 13.5 14.75H4.5C4.00754 14.75 3.51991 14.653 3.06494 14.4645C2.60997 14.2761 2.19657 13.9999 1.84835 13.6517C1.14509 12.9484 0.75 11.9946 0.75 11V5C0.75 4.00544 1.14509 3.05161 1.84835 2.34835C2.55161 1.64509 3.50544 1.25 4.5 1.25H13.5C13.9925 1.25 14.4801 1.347 14.9351 1.53545C15.39 1.72391 15.8034 2.00013 16.1517 2.34835C16.4999 2.69657 16.7761 3.10997 16.9645 3.56494C17.153 4.01991 17.25 4.50754 17.25 5ZM3.75 6.5C3.75 6.69891 3.82902 6.88968 3.96967 7.03033C4.11032 7.17098 4.30109 7.25 4.5 7.25H9C9.19891 7.25 9.38968 7.17098 9.53033 7.03033C9.67098 6.88968 9.75 6.69891 9.75 6.5C9.75 6.30109 9.67098 6.11032 9.53033 5.96967C9.38968 5.82902 9.19891 5.75 9 5.75H4.5C4.30109 5.75 4.11032 5.82902 3.96967 5.96967C3.82902 6.11032 3.75 6.30109 3.75 6.5ZM14.25 9.5C14.25 9.30109 14.171 9.11032 14.0303 8.96967C13.8897 8.82902 13.6989 8.75 13.5 8.75H4.5C4.30109 8.75 4.11032 8.82902 3.96967 8.96967C3.82902 9.11032 3.75 9.30109 3.75 9.5C3.75 9.69891 3.82902 9.88968 3.96967 10.0303C4.11032 10.171 4.30109 10.25 4.5 10.25H13.5C13.6989 10.25 13.8897 10.171 14.0303 10.0303C14.171 9.88968 14.25 9.69891 14.25 9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BlogIcon;
