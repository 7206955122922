interface Props {
  className?: string;
}

const RocketIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1501 13.1999C13.1501 13.1999 15.5334 10.8333 16.3417 9.99994C18.9001 7.49994 17.6251 2.34994 17.6251 2.34994C17.6251 2.34994 12.5001 1.07494 10.0001 3.63327C8.05008 5.53327 6.78341 6.84994 6.78341 6.84994C6.78341 6.84994 3.58341 6.18327 1.66675 8.09994L11.8751 18.3333C13.7917 16.3916 13.1501 13.1999 13.1501 13.1999ZM11.9001 5.69994C12.0549 5.54498 12.2387 5.42204 12.441 5.33817C12.6433 5.2543 12.8602 5.21113 13.0792 5.21113C13.2983 5.21113 13.5152 5.2543 13.7175 5.33817C13.9198 5.42204 14.1036 5.54498 14.2584 5.69994C14.4913 5.9331 14.6499 6.23007 14.714 6.55332C14.7782 6.87657 14.7451 7.21159 14.6189 7.51603C14.4927 7.82046 14.279 8.08065 14.005 8.2637C13.731 8.44676 13.4088 8.54446 13.0792 8.54446C12.7497 8.54446 12.4275 8.44676 12.1535 8.2637C11.8794 8.08065 11.6658 7.82046 11.5396 7.51603C11.4134 7.21159 11.3803 6.87657 11.4445 6.55332C11.5086 6.23007 11.6672 5.9331 11.9001 5.69994ZM2.50008 17.4999C4.04264 17.4559 5.51937 16.8652 6.66675 15.8333L4.16675 13.3333C2.50008 14.1666 2.50008 17.4999 2.50008 17.4999Z" fill="currentColor"/>
    </svg>
  );
};

export default RocketIcon;
