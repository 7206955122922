'use client';

import ExclamationIcon from '@opulous/web/src/components/Icons/Exclamation';
import { ChangeEvent, Fragment } from 'react';

interface Props {
  type: any;
  label?: any;
  value?: any;
  className?: any;
  readOnly?: boolean;
  isError?: boolean;
  fontColor?: string;
  placeholder: any;
  prefixIcon?: JSX.Element;
  disable?: boolean;
  radius?: string;
  border?: string;
  inputError?: boolean;
  handleChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (evt: any) => void;
  onBlur?: () => void;
}

export default function Input({
  label,
  placeholder,
  className,
  isError = false,
  inputError = false,
  type,
  radius = 'rounded-xl',
  fontColor = 'text-surfaces-003 dark:text-white',
  readOnly = false,
  value,
  border = 'border-[0.125rem]',
  disable,
  handleChange,
  onClick,
  onBlur,
  prefixIcon,
}: Props) {
  return (
    <Fragment>
      {label && <p className="text-sm font-medium mb-2 dark:text-dark-surfaces-004">{label}</p>}
      <input
        type={type}
        value={value}
        readOnly={readOnly}
        disabled={disable}
        className={`
          p-4 ${radius} ${border} focus:outline-none dark:bg-pro-black w-full text-sm ${fontColor}
          ${className}
          ${
            isError
              ? 'focus:border-error-700 dark:focus:border-error-700 border-error-700 dark:border-error-700'
              : 'focus:border-pro-black dark:focus:border-primary-primary border-surfaces-009 disabled:border-surfaces-010 dark:border-[rgb(11,11,11)]/30'
          }
        `}
        placeholder={placeholder}
        onChange={handleChange}
        onClick={onClick}
        onBlur={onBlur}
      ></input>
      {prefixIcon && prefixIcon}
      {inputError && (
        <div className="absolute top-[66%] right-5 transform -translate-y-1/2 text-error-700">
          <ExclamationIcon className="h-6 w-6" />
        </div>
      )}
    </Fragment>
  );
}
