/* eslint-disable no-param-reassign */
'use client';

import Button from '@opulous/web/src/components/Form/Button';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Input from '@opulous/web/src/components/Form/Input';
import WalletContext from '@opulous/web/src/context/context';
import Modal from '@opulous/web/src/components/Modal';
import WalletIcon from '@opulous/web/src/components/Icons/Wallet';
import StarIcon from '@opulous/web/src/components/Icons/Star';
import WaterIcon from '@opulous/web/src/components/Icons/Water';
import TimerIcon from '@opulous/web/src/components/Icons/Timer';
import ChevronRightIcon from '@opulous/web/src/components/Icons/ChevronRight';
import InfoIcon from '@opulous/web/src/components/Icons/Info';
import EditIcon from '@opulous/web/src/components/Icons/edit';
import { raffles as RaffleService } from '@opulous/web/src/services';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { AlertError } from '@opulous/web/src/components/shared';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #23262F;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 285px;
    text-align: center;
  }
  & .MuiTooltip-arrow {
    color: #23262F;
  }
`;

export default function MigrationPage() {
  const [address, setAddress] = useState('');
  function handleChangeEmail(e: any) {
    setAddress(e.target.value);
  }

  const {
    state: { wallet },
    setIsWalletConnectDialogOpen,
  } = useContext(WalletContext);

  const [isFetchTicketsBalanceLoading, setIsFetchTicketsBalanceLoading] =
    useState(false);
  const [ticketsAmount, setTicketsAmount] = useState(0);
  const [opulPriceInUSD, setOpulPriceInUSD] = useState(0);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isWalletArbAddressSaved, setIsWalletArbAddressSaved] = useState(false);
  const [isEditWalletModalOpen, setIsEditWalletModalOpen] = useState(false);
  const [arbAddressSaved, setArbAddressSaved] = useState('');
  const [isFetchArbAddressLoading, setIsFetchArbAddressLoading] =
    useState(false);
  const [migratedTicketsAmount, setMigratedTicketsAmountAmount] = useState(0);
  const [isSaveArbAddressLoading, setIsSaveArbAddressLoading] = useState(false);
  const [isWalletMigrateTicketsLoading, setIsWalletMigrateTicketsLoading] = useState(false);
  const [alertError, setAlertError] = useState<
    | {
      show: boolean;
      message: string;
    }
    | undefined
  >();

  const digitGrouping = (
    payload: number | string,
    decimalPlaces = 0,
    addZeros = true,
  ) => {
    if (typeof payload !== 'string') {
      payload = payload.toString();
    }

    const [before, decimal] = payload.split('.');

    let dec = decimal ? decimal.substring(0, decimalPlaces) : '';

    while (dec.length < decimalPlaces && addZeros) {
      dec += '0';
    }

    if (decimal && Number(dec) !== 0) {
      return before.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + dec;
    } else {
      return before.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const fetchWalletTicketBalance = useCallback(async () => {
    setIsFetchTicketsBalanceLoading(true);
    try {
      const {
        data: {
          data: { totalAvailableTicket, opulPrice },
        },
      } = await RaffleService.getWalletTicketBalance(
        wallet,
      );
      setTicketsAmount(totalAvailableTicket);
      setOpulPriceInUSD(opulPrice)
    } catch (error) {
      console.log('error fetchWalletTicketBalance: ', error);
      setTicketsAmount(0);
      setOpulPriceInUSD(0)
    } finally {
      setIsFetchTicketsBalanceLoading(false);
    }
  }, [RaffleService, wallet]);

  useEffect(() => {
    if (wallet) {
      fetchWalletTicketBalance();
    } else {
      setTicketsAmount(0);
    }
  }, [wallet, fetchWalletTicketBalance]);

  const clearArbAddressForm = () => {
    setArbAddressSaved('');
    setIsWalletArbAddressSaved(false);
  };

  const fetchWalletArbAddress = useCallback(async () => {
    setIsFetchArbAddressLoading(true);
    try {
      const { data } = await RaffleService.getWalletArbAddress(
        wallet,
      );
      if (data.evm_wallet) {
        setArbAddressSaved(data.evm_wallet);
        setIsWalletArbAddressSaved(true);
      } else {
        clearArbAddressForm();
      }
    } catch (error) {
      console.log('error fetchWalletArbAddress: ', error);
      clearArbAddressForm();
    } finally {
      setIsFetchArbAddressLoading(false);
    }
  }, [RaffleService, wallet]);

  useEffect(() => {
    if (wallet) {
      fetchWalletArbAddress();
    } else {
      clearArbAddressForm();
    }
  }, [wallet, fetchWalletArbAddress]);

  const saveWalletArbAddress = async ({ isEdit = false }) => {
    setIsSaveArbAddressLoading(true);
    try {
      const { data } = await RaffleService.saveWalletArbAddress(
        wallet,
        address,
        isEdit
      );
      if (data.evm_wallet) {
        setIsWalletArbAddressSaved(true);
        setArbAddressSaved(data.evm_wallet);
      } else {
        setAlertError({
          show: true,
          message: 'An error occurred while trying to Save Address',
        });
      }
    } catch (error: any) {
      console.log('error saveWalletArbAddress: ', error);
      setAlertError({
        show: true,
        message: 'An error occurred while trying to Save Address',
      });
    } finally {
      setIsSaveArbAddressLoading(false);
      setIsEditWalletModalOpen(false);
    }
  };

  const ticketsAfterMigration = useMemo(() => {
    return ticketsAmount * opulPriceInUSD;
  }, [ticketsAmount, opulPriceInUSD])

  const migrateTickets = async () => {
    setIsWalletMigrateTicketsLoading(true);
    try {
      const { data } = await RaffleService.postWalletMigrateTickets(
        wallet,
        arbAddressSaved,
        ticketsAmount
      );
      if (data.status === 'success') {
        setMigratedTicketsAmountAmount(ticketsAfterMigration);
        setIsCompleteModalOpen(true);
        fetchWalletTicketBalance();
      } else {
        setAlertError({
          show: true,
          message: 'An error occurred while trying to Migrate Tickets',
        });
      }
    } catch (error: any) {
      console.log('error migrateTickets: ', error);
      setAlertError({
        show: true,
        message: 'An error occurred while trying to load Migrate Tickets',
      });
    } finally {
      setIsWalletMigrateTicketsLoading(false);
    }
  };

  const onMigrationCompleteModalClosed = () => {
    setIsCompleteModalOpen(false);
    setMigratedTicketsAmountAmount(0);
  };

  const walletClassName =
    'flex flex-shrink-0 h-6 md:h-8 text-primary-001 w-6 lg:w-8';
  const algorandPlatformLinks = [
    {
      label: 'Assets',
      icon: <WalletIcon className={walletClassName} />,
      link: '/assets',
    },
    {
      label: 'Rewards',
      icon: <StarIcon className={walletClassName} />,
      link: '/rewards',
    },
    {
      label: 'Pools',
      icon: <WaterIcon className={walletClassName} />,
      link: '/pools',
    },
    {
      label: 'Vesting',
      icon: <TimerIcon className={walletClassName} />,
      link: '/vesting',
    },
  ];

  return (
    <div className="flex flex-col w-full">
      {alertError && <AlertError message={alertError.message} onClose={() => setAlertError(undefined)} />}

      <div className="container flex flex-col mx-auto w-full max-w-[47.25rem] pt-[2.5rem] lg:pt-[6rem] pb-[104px] lg:pb-[6rem] px-1 sm:px-0 justify-center items-center">
        <div className="mb-6 lg:mb-[4.375rem] flex flex-col gap-4 items-center justify-center">
          <div className="text-center text-[2.5rem] lg:text-[3.375rem] leading-[3rem] lg:leading-[4.05rem] font-bold tracking-[-2%] text-surfaces-003 dark:text-white">
            {"We're transitioning to Arbitrum!"}
          </div>
          <div className="text-center text-[1.125rem] lg:text-[1.375rem] leading-[1.6875rem] lg:leading-[2.0625rem] text-surfaces-004 dark:text-dark-surfaces-004">
            Migrate your Tickets to Arbitrum to continue enjoying the benefits.
          </div>
        </div>
        {/* step 1 */}
        {!wallet && (
          <>
            <div className="rounded-2xl p-5 lg:p-10 bg-white dark:bg-surfaces-001 w-full flex flex-col gap-4 sm:gap-6">
              <div className="text-[1.5rem] sm:text-[2rem] font-semibold leading-[1.2]">
                Tickets
              </div>

              <div className="flex flex-col gap-1">
                <div className="font-medium text-[0.875rem] leading-[1.3125rem] text-surfaces-004 dark:text-dark-surfaces-004">
                  Migrate your Tickets from Algorand to Arbitrum. Start by
                  connecting your Algo wallet.
                </div>
              </div>

              <Button
                variant="primary"
                className="h-[3rem] lg:h-[3.25rem] w-full"
                action={() => setIsWalletConnectDialogOpen(true)}
              >
                Connect Algorand wallet
              </Button>
            </div>
          </>
        )}
        {/* end of step 1 */}

        {/* step 2 */}
        {!!wallet && (
          <>
            <div className="rounded-2xl p-5 lg:p-10 bg-white dark:bg-surfaces-001 w-full flex flex-col gap-4 sm:gap-6">
              <div className="text-[1.5rem] sm:text-[2rem] font-semibold leading-[1.2]">
                Tickets
              </div>

              <div className="flex flex-col lg:flex-row justify-center w-full gap-3">
                <div className="lg:min-w-[27.3125rem] w-full">
                  <div className="mb-1 font-medium text-[0.875rem] leading-[1.3125rem] text-surfaces-003 dark:text-dark-surfaces-004">
                    Algorand wallet
                  </div>
                  <div className="w-full rounded-[0.75rem] border border-surfaces-010 dark:border-dark-surfaces-007 p-[0.875rem] bg-surfaces-012 dark:bg-dark-surfaces-012">
                    <div className="text-[1rem] leading-6 text-surfaces-003 dark:text-white break-words">
                      {wallet}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row justify-center w-full gap-1 sm:gap-3 bg-surfaces-012 dark:bg-dark-surfaces-012 rounded-xl p-3 lg:p-0 lg:bg-transparent">
                <div className="flex-1 rounded-[0.75rem] lg:px-5 lg:py-4 bg-surfaces-012 dark:bg-dark-surfaces-012 text-surfaces-003 dark:text-white flex flex-row lg:flex-col justify-between">
                  <div className="text-[0.75rem] sm:text-[0.875rem] leading-[1.3125rem] text-surfaces-006 dark:text-dark-surfaces-004">
                    Tickets available to transfer
                  </div>
                  <div className="w-auto lg:w-full">
                    {isFetchTicketsBalanceLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : (
                      <div className="text-[0.875rem] sm:text-[1rem] leading-[1.3125rem] md:leading-6">
                        {digitGrouping(ticketsAmount)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-0.5 rounded-[0.75rem] lg:px-5 lg:py-4 bg-surfaces-012 dark:bg-dark-surfaces-012 text-surfaces-003 dark:text-white flex flex-row lg:flex-col justify-between">
                  <div className="text-[0.75rem] sm:text-[0.875rem] leading-[1.3125rem] text-surfaces-006 dark:text-dark-surfaces-004 flex items-center">
                    <div className="text-[0.75rem] sm:text-[0.875rem] leading-[1.3125rem]">
                      OPUL Price
                    </div>

                    <CustomTooltip
                      title="All tickets generated on Arbitrum are multiplied by the current OPUL price. This approach ensures fairness for all users, both old and new."
                      arrow
                      placement="top"
                    >
                      <Box>
                        <InfoIcon className="lg:ml-1 cursor-pointer w-4 h-4 lg:w-5 lg:h-5" />
                      </Box>
                    </CustomTooltip>
                  </div>
                  <div className="w-auto lg:w-full">
                    {isFetchTicketsBalanceLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : (
                      <div className="text-[0.875rem] sm:text-[1rem] leading-[1.3125rem] md:leading-6">
                        x {digitGrouping(opulPriceInUSD, 2, false)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex-1 rounded-[0.75rem] lg:px-5 lg:py-4 bg-surfaces-012 dark:bg-dark-surfaces-012 text-surfaces-003 dark:text-white flex flex-row lg:flex-col justify-between">
                  <div className="text-[0.75rem] sm:text-[0.875rem] leading-[1.3125rem] text-surfaces-006 dark:text-dark-surfaces-004">
                    Tickets after migration
                  </div>
                  <div className="w-auto lg:w-full">
                    {isFetchTicketsBalanceLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : (
                      <div className="text-[0.875rem] sm:text-[1rem] leading-[1.3125rem] md:leading-6">
                        {digitGrouping(ticketsAfterMigration)}
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <div>
                {isWalletArbAddressSaved || isFetchArbAddressLoading ? (
                  <div>
                    <div className="mb-1 font-medium text-[0.875rem] leading-5 text-surfaces-003 dark:text-dark-surfaces-004">
                      Arbitrum wallet address
                    </div>
                    <div
                      className="w-full rounded-[0.75rem] border border-surfaces-010 dark:border-dark-surfaces-007 p-[0.875rem] bg-surfaces-012 dark:bg-dark-surfaces-012 flex gap-3"
                      style={{ paddingTop: 13.35, paddingBottom: 13.35 }}
                    >
                      <div className="text-[1rem] leading-6 text-surfaces-003 dark:text-white break-all flex-grow">
                        {isFetchArbAddressLoading ? (
                          <CircularProgress color="inherit" size={16} />
                        ) : (
                          <>{arbAddressSaved}</>
                        )}
                      </div>

                      {isWalletArbAddressSaved ? (
                        <button onClick={() => setIsEditWalletModalOpen(true)}>
                          <EditIcon className="h-6 w-6 text-primary-001" />
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <Input
                    type="text"
                    label="Arbitrum wallet address"
                    className={[
                      'sm:text-base sm:leading-none h-[3rem] lg:h-[3.25rem] dark:!text-surfaces-006 dark:disabled:!bg-dark-surfaces-012 dark:!bg-surfaces-001 dark:!border-dark-surfaces-007 -mt-1',
                      // validationFailed ? 'border-error-800 pr-12' : '',
                    ].join(' ')}
                    border="border-[0.0625rem]"
                    placeholder="Arbitrum wallet address"
                    value={address}
                    handleChange={handleChangeEmail}
                    disable={ticketsAmount <= 0}
                  />
                )}
              </div>

              {isWalletArbAddressSaved ? (
                <Button
                  variant="primary"
                  className={[
                    'bg-primary-001 font-medium h-[3rem] lg:h-[3.25rem] leading-6 flex w-full items-center justify-center rounded-[3.875rem] text-base lg:text-lg text-white',
                    'disabled:bg-surfaces-009 dark:disabled:bg-dark-surfaces-009 disabled:text-surfaces-005 disabled:opacity-100',
                  ].join(' ')}
                  disabled={!arbAddressSaved || ticketsAfterMigration < 1}
                  loading={isWalletMigrateTicketsLoading}
                  action={() => migrateTickets()}
                >
                  Migrate tickets
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={[
                    'bg-primary-001 font-medium h-[3rem] lg:h-[3.25rem] leading-6 flex w-full items-center justify-center rounded-[3.875rem] text-base lg:text-lg text-white',
                    'disabled:bg-surfaces-009 dark:disabled:bg-dark-surfaces-009 disabled:text-surfaces-005 disabled:opacity-100',
                  ].join(' ')}
                  disabled={address === '' || ticketsAmount <= 0}
                  loading={isSaveArbAddressLoading}
                  action={() => saveWalletArbAddress({ isEdit: false })}
                >
                  {
                    ticketsAmount <= 0
                      ? 'Insufficient tickets amount'
                      : address === ''
                        ? 'Enter Arbitrum wallet address'
                        : 'Save wallet'
                  }
                </Button>
              )}
            </div>

            <div className="mt-[1.125rem] rounded-2xl p-5 lg:p-10 bg-white dark:bg-surfaces-001 w-full flex flex-col gap-4 lg:gap-6">
              <div className="text-[1.5rem] sm:text-[2rem] font-semibold leading-[1.2]">
                MFTs
              </div>

              <div className="text-[0.875rem] lg:text-[1rem] leading-[1.3125rem] lg:leading-[1.5rem] text-surfaces-004 dark:text-dark-surfaces-004">
                Migrate your Algorand MFTs today to continue enjoying the
                benefits. Remember, all future reward distributions will only
                happen on Arbitrum. Start your migration process now to stay
                ahead.
              </div>

              <a href="https://messina.one/nft_bridge" target="_blank">
                <button
                  className={`
                    w-full flex items-center justify-center rounded-[3.875rem] py-3 bg-surfaces-012 dark:bg-dark-surfaces-012
                    font-medium text-[1rem] sm:text-[1.125rem] leading-[1.5rem] sm:leading-[1.6875rem] text-surfaces-001
                  `}

                >
                  Access MFT Bridge
                </button>
              </a>
            </div>
          </>
        )}

        <div className="mt-10 lg:mt-[4.375rem] flex flex-col gap-6 w-full">
          <div className="text-[1.5rem] sm:text-[2rem] font-semibold leading-[1.2]">
            Links to the Algorand platform
          </div>

          <div className="grid grid-cols-12 gap-3 sm:gap-4">
            {algorandPlatformLinks.map((item, i) => {
              return (
                <a
                  key={'link-' + i}
                  href={item.link}
                  className="col-span-12 sm:col-span-6"
                >
                  <div className="rounded-2xl bg-white dark:bg-surfaces-001 p-5 lg:py-6 lg:px-8 flex gap-4 items-center">
                    <div className="bg-secondary-009 dark:bg-dark-secondary-009 flex flex-col h-12 lg:h-14 items-center justify-center w-12 lg:w-14 rounded-full">
                      {item.icon}
                    </div>

                    <div className="flex-grow flex gap-2 items-center">
                      <div className="flex-grow text-[1.25rem]">
                        {item.label}
                      </div>

                      <ChevronRightIcon className="w-6 h-6 text-surfaces-006"></ChevronRightIcon>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        {/* end of step 2 */}
      </div>

      <Modal
        title="Migration complete"
        width="w-full lg:w-[512px]"
        show={isCompleteModalOpen}
        onClose={() => {
          onMigrationCompleteModalClosed();
        }}
      >
        <div className="mt-3 sm:mt-6 flex flex-col gap-3 sm:gap-6">
          <div className="text-[0.875rem] lg:text-[1rem]">
            You have migrated tickets to the Arbitrum platform. Access{' '}
            <a
              href="http://opulous.org/tickets"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-primary"
            >
              http://opulous.org/tickets
            </a>{' '}
            to claim them.
          </div>

          <div className="w-full rounded-lg p-3 bg-surfaces-011 dark:bg-dark-surfaces-011 flex justify-between items-center">
            <div>
              <div className="text-xs text-surfaces-005 dark:text-surfaces-005">
                Migrated tickets
              </div>
              <div style={{ marginTop: 2 }}>
                {digitGrouping(migratedTicketsAmount)}
              </div>
            </div>

            <div>
              <a href="http://opulous.org/tickets">
                <Button variant="primary">Claim</Button>
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Edit wallet"
        width="w-full lg:w-[512px]"
        show={isEditWalletModalOpen}
        onClose={() => {
          setIsEditWalletModalOpen(false);
        }}
      >
        <div className="mt-3 sm:mt-6 flex flex-col gap-3 sm:gap-6">
          <div>
            <Input
              type="text"
              label="Arbitrum wallet address"
              className={[
                'sm:text-base sm:leading-none h-[3rem] lg:h-[3.25rem] dark:!text-surfaces-006 dark:disabled:!bg-dark-surfaces-012 dark:!bg-surfaces-001 dark:!border-dark-surfaces-007 -mt-1',
                // validationFailed ? 'border-error-800 pr-12' : '',
              ].join(' ')}
              border="border-[0.0625rem]"
              placeholder="Arbitrum wallet address"
              value={address}
              handleChange={handleChangeEmail}
            />
          </div>

          <Button
            variant="primary"
            className={[
              'bg-primary-001 font-medium h-[3rem] lg:h-[3.25rem] leading-6 flex w-full items-center justify-center rounded-[3.875rem] text-base lg:text-lg text-white',
              'disabled:bg-surfaces-009 dark:disabled:bg-dark-surfaces-009 disabled:text-surfaces-005 disabled:opacity-100',
            ].join(' ')}
            disabled={address === '' || address === arbAddressSaved}
            loading={isSaveArbAddressLoading}
            action={() => saveWalletArbAddress({ isEdit: true })}
          >
            {address === '' ? 'Enter Arbitrum wallet address' : 'Save wallet'}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
