/* eslint-disable max-len */
export default function MenuIcon({ className }: { className?: string }) {
  return (
    <svg
      className={`${className}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33331 8H26.6666V10.6667H5.33331V8ZM5.33331 14.6667H26.6666V17.3333H5.33331V14.6667ZM5.33331 21.3333H26.6666V24H5.33331V21.3333Z"
        fill="currentColor"
      />
    </svg>

    // <svg
    //   className={`${className}`}
    //   width="32"
    //   height="32"
    //   viewBox="0 0 32 32"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M25 14H7"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M25 10H7"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M25 18H7"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M25 22H7"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
}
