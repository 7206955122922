interface Props {
  className?: string;
}

const PeopleIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.4016 10.1087H14.0096C14.1514 10.4971 14.229 10.9164 14.229 11.3533V16.6145C14.229 16.7967 14.1973 16.9715 14.1396 17.1342H16.4409C17.3006 17.1342 18 16.4348 18 15.5751V12.707C18 11.2743 16.8344 10.1087 15.4016 10.1087ZM3.77104 11.3534C3.77104 10.9164 3.84855 10.4972 3.99045 10.1087H2.5984C1.16564 10.1087 0 11.2743 0 12.7071V15.5752C0 16.4348 0.699363 17.1342 1.55904 17.1342H3.86044C3.80121 16.9674 3.77097 16.7916 3.77104 16.6145V11.3534ZM10.5912 8.75498H7.40879C5.97604 8.75498 4.81039 9.92062 4.81039 11.3534V16.6145C4.81039 16.9015 5.04306 17.1342 5.33007 17.1342H12.6699C12.9569 17.1342 13.1896 16.9015 13.1896 16.6145V11.3534C13.1896 9.92062 12.024 8.75498 10.5912 8.75498ZM9 1.86523C7.27692 1.86523 5.8751 3.26705 5.8751 4.99017C5.8751 6.15894 6.52018 7.1797 7.47285 7.71562C7.92471 7.9698 8.44566 8.11507 9 8.11507C9.55434 8.11507 10.0753 7.9698 10.5272 7.71562C11.4799 7.1797 12.1249 6.1589 12.1249 4.99017C12.1249 3.26709 10.7231 1.86523 9 1.86523ZM3.51274 4.77789C2.22409 4.77789 1.17573 5.82625 1.17573 7.11491C1.17573 8.40356 2.22409 9.45192 3.51274 9.45192C3.82942 9.45219 4.1428 9.38773 4.43366 9.2625C4.92254 9.05202 5.32564 8.67943 5.57508 8.21273C5.75577 7.87504 5.85013 7.4979 5.84975 7.11491C5.84975 5.82629 4.80139 4.77789 3.51274 4.77789ZM14.4873 4.77789C13.1986 4.77789 12.1502 5.82625 12.1502 7.11491C12.1499 7.4979 12.2442 7.87504 12.4249 8.21273C12.6744 8.67947 13.0775 9.05205 13.5663 9.2625C13.8572 9.38773 14.1706 9.45219 14.4873 9.45192C15.7759 9.45192 16.8243 8.40356 16.8243 7.11491C16.8243 5.82625 15.7759 4.77789 14.4873 4.77789Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PeopleIcon;
