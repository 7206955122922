'use client';

import { ReactNode, useEffect } from 'react';
import Close from '@opulous/web/src/components/Icons/Close';

interface Props {
  title?: string | ReactNode;
  width: string;
  padding?: string;
  className?: string;
  children: ReactNode;
  show?: boolean;
  bgModal?: string;
  onClose?: (e?: any) => void;
  titleClassName?: string;
  titleWrapperClassName?: string;
  overflowVisible?: boolean;
  inserted?: ReactNode;
  slideAnimation?: boolean;
  backdropZindex?: string;
  modalZindex?: string;
}

export default function ModalSide({
  title,
  children,
  width = 'w-full lg:w-auto',
  padding = 'pb-9 p-4 lg:p-10',
  bgModal = 'bg-white dark:bg-surfaces-001',
  className,
  show,
  onClose,
  titleClassName = 'font-semibold leading-[2.1rem] normal-case text-surfaces-003  dark:text-white text-[1.75rem]',
  titleWrapperClassName = 'flex items-center justify-between',
  overflowVisible = false,
  inserted,
  slideAnimation = false,
  backdropZindex = 'z-[1000]',
  modalZindex = 'z-[1001]',
}: Props) {
  useEffect(() => {
    const handleWindowWheel = (e: Event) => {
      if (show) {
        e.preventDefault();
      }
    };
    const handleChildWheel = (e: Event) => {
      e.stopPropagation();
    };

    const bodyRef = Array.from(document.getElementsByTagName('BODY'));
    bodyRef.forEach((item) => {
      item.addEventListener('wheel', handleWindowWheel, { passive: false });
      item.addEventListener('touchmove', handleWindowWheel, { passive: false });
    });

    let modalRef: Element[] = [];
    setTimeout(() => {
      const overflowContents = Array.from(document.getElementsByClassName('overscroll-y-contain'));
      modalRef = overflowContents;
      modalRef.forEach((item) => {
        // only if modal-inner has scroll
        if (item.scrollHeight > item.clientHeight) {
          item.addEventListener('wheel', handleChildWheel);
          item.addEventListener('touchmove', handleChildWheel);
        }
      });
    }, 100);

    return function cleanup() {
      bodyRef.forEach((item) => {
        item.removeEventListener('wheel', handleWindowWheel);
        item.removeEventListener('touchmove', handleWindowWheel);
      });
      modalRef.forEach((item) => {
        item.removeEventListener('wheel', handleChildWheel);
        item.removeEventListener('touchmove', handleChildWheel);
      });
    };
  }, [show]);
  
  return (
    <div
      className={`
        ${show ? 'visible opacity-100' : 'invisible opacity-0'}
        transition-all ease-in-out duration-300
        w-[100dvw] h-[100dvh] max-w-full fixed top-0 left-0
        flex items-end lg:items-start justify-center lg:justify-end backdrop-blur-sm bg-[rgb(51,51,51)]/[.35] dark:bg-[rgb(0,0,0)]/[.65] ${backdropZindex}
      `}
    >
      <div
        className={`
          w-[100dvw] h-[100dvh] max-w-full fixed top-0 left-0 ${backdropZindex}
        `}
        onClick={onClose}
      ></div>

      <div
        className={`
          modal-inner overscroll-y-contain relative
          transition-all ease-in-out duration-300
          ${
            slideAnimation ?
            (show ? 'translate-x-0' : 'translate-x-full')
            :
            (show ? 'translate-y-0' : 'translate-y-full lg:translate-y-0')
          }
          ${width} ${padding} rounded-t-[1rem] lg:rounded-none
          shadow-[0_20px_45px_0_rgba(0,0,0,0.05)]
          relative max-h-[95%] lg:max-h-full lg:h-full ${modalZindex}
          flex flex-col ${bgModal}
          ${overflowVisible ? 'overflow-visible' : 'overflow-y-auto'}
          ${className}
        `}
      >
        {inserted}
        <div className={titleWrapperClassName}>
          <h4 className={titleClassName}>{title}</h4>

          <button
            onClick={onClose}
            className={`rounded-full hover:bg-surfaces-012 dark:hover:bg-dark-surfaces-012 transition-all ease-in-out duration-200`}
          >
            <Close className={`w-8 h-8 text-surfaces-300 dark:text-white`}></Close>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
}
