export enum breakpoints {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  dekstop = 1440,
  '2xl' = 1536,
  '3xl' = 1920,
  '4xl' = 2400,
  '2k' = 2560,
}

export const imgLoader = ({ src, width }: { src: string; width: number }) => {
  const array = ['?w=', '?wid=', '?width='];
  if (array.some((v) => src.includes(v))) return src;

  return src + '?w=' + width;
};
