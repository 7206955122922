/* eslint-disable max-len */
export default function LogoutIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.92425 3V11.6746H11.0758V3H8.92425ZM4.69635 5.19635C3.33911 6.55359 2.5 8.42893 2.5 10.5C2.5 14.6421 5.85786 18 10 18C14.1421 18 17.5 14.6421 17.5 10.5C17.5 8.42893 16.6609 6.55359 15.3036 5.19635L13.793 6.70698C14.7638 7.6777 15.3641 9.01888 15.3641 10.5C15.3641 13.4623 12.9622 15.8641 10 15.8641C7.03774 15.8641 4.63593 13.4623 4.63593 10.5C4.63593 9.01888 5.23624 7.6777 6.20698 6.70698L4.69635 5.19635Z"
        fill="currentColor"
      />
    </svg>
  );
}
