interface Props {
  className?: string;
}
const RewardIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.21053 10.3752C5.21053 10.1432 5.1218 9.92062 4.96388 9.75653C4.80595 9.59243 4.59176 9.50024 4.36842 9.50024H1.84211C1.61877 9.50024 1.40457 9.59243 1.24665 9.75653C1.08872 9.92062 1 10.1432 1 10.3752V16.5002H5.21053V10.3752ZM11.1053 6.87524C11.1053 6.64318 11.0165 6.42062 10.8586 6.25653C10.7007 6.09243 10.4865 6.00024 10.2632 6.00024H7.73684C7.5135 6.00024 7.29931 6.09243 7.14138 6.25653C6.98346 6.42062 6.89474 6.64318 6.89474 6.87524V16.5002H11.1053V6.87524ZM17 3.37524C17 3.14318 16.9113 2.92062 16.7534 2.75653C16.5954 2.59243 16.3812 2.50024 16.1579 2.50024H13.6316C13.4082 2.50024 13.194 2.59243 13.0361 2.75653C12.8782 2.92062 12.7895 3.14318 12.7895 3.37524V16.5002H17V3.37524Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RewardIcon;
