import CryptoJS from "crypto-js";
import config from '@opulous/web/src/config';

function encrypt(text: string, secretKey: string): string {
  const ciphertext = CryptoJS.AES.encrypt(text, secretKey)?.toString();
  return ciphertext;
}

function generateRandomString(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function generateToken(): string {
  const expired: number = Math.floor(Date.now() / 1000) + 10; // will expired in 10 second
  const secretKey: string = config.env.SECRET_KEY;
  const encryptedExpired: string = encrypt(
    `${expired.toString()}_${generateRandomString(5)}`,
    secretKey,
  );
  return encryptedExpired;
}
