interface Props {
  className?: string;
}

const QuestionMarkIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="clip0_5841_15813">
        <path
          d="M9.00039 0.5C4.03147 0.5 0.000366211 4.52602 0.000366211 9.49527C0.000366211 14.4693 4.03147 18.5 9.00035 18.5C13.972 18.5 17.9997 14.4692 17.9997 9.49527C17.9997 4.52602 13.972 0.5 9.00039 0.5ZM9.64173 14.5031C9.4327 14.6891 9.19045 14.7826 8.91603 14.7826C8.6321 14.7826 8.38443 14.6908 8.17303 14.5068C7.96129 14.3232 7.85521 14.0661 7.85521 13.7357C7.85521 13.4426 7.95787 13.196 8.16252 12.9961C8.36717 12.7962 8.61822 12.6962 8.91603 12.6962C9.20908 12.6962 9.45575 12.7962 9.65598 12.9961C9.85588 13.196 9.95617 13.4426 9.95617 13.7357C9.95579 14.0613 9.85109 14.3171 9.64173 14.5031ZM12.2481 8.04145C12.0875 8.33926 11.8968 8.59605 11.6756 8.81258C11.455 9.02907 11.0583 9.39294 10.4857 9.90453C10.3278 10.0489 10.2007 10.1756 10.1055 10.2847C10.0104 10.3941 9.9392 10.494 9.89277 10.5848C9.84761 10.6717 9.8114 10.763 9.78469 10.8572C9.75927 10.9477 9.721 11.1073 9.66915 11.3353C9.58108 11.8191 9.30428 12.061 8.83908 12.061C8.59717 12.061 8.39389 11.982 8.22821 11.8238C8.0632 11.6656 7.98088 11.4308 7.98088 11.1191C7.98088 10.7285 8.04152 10.39 8.16248 10.1037C8.28277 9.81742 8.4437 9.56638 8.6436 9.34989C8.84383 9.1334 9.11351 8.87656 9.45333 8.57875C9.75114 8.31821 9.96629 8.12172 10.0988 7.98922C10.2304 7.85783 10.3431 7.70867 10.4335 7.54607C10.5246 7.3831 10.5694 7.20658 10.5694 7.01585C10.5694 6.64352 10.4315 6.32979 10.1544 6.074C9.87756 5.8182 9.52044 5.69012 9.08304 5.69012C8.57112 5.69012 8.19437 5.8192 7.95245 6.07737C7.71054 6.33554 7.50626 6.71567 7.33854 7.21813C7.17998 7.74397 6.8798 8.00685 6.43832 8.00685C6.17777 8.00685 5.95791 7.91503 5.77868 7.73139C5.59979 7.54774 5.51034 7.34888 5.51034 7.13477C5.51034 6.69295 5.6523 6.24505 5.9359 5.7914C6.21983 5.33775 6.63384 4.962 7.17828 4.66452C7.72237 4.36671 8.35767 4.21762 9.08304 4.21762C9.7576 4.21762 10.3529 4.34229 10.8692 4.59133C11.3856 4.84 11.7847 5.17849 12.0662 5.60671C12.3474 6.03461 12.4883 6.49981 12.4883 7.00227C12.489 7.39702 12.4087 7.74364 12.2481 8.04145Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5841_15813">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuestionMarkIcon;
