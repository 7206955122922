interface Props {
  className?: string;
}

const FireIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9636 13.4349C17.9636 18.3493 13.9796 22.3333 9.06516 22.3333C4.1628 22.3333 0.20347 18.4048 0.167001 13.5026C0.153314 11.667 0.695469 9.9589 1.63517 8.53643C1.73503 8.38527 2.06538 7.92272 2.38343 7.48049C2.74029 6.98431 3.45647 6.92773 3.88863 7.35992C4.09674 7.56802 4.35301 7.72152 4.63469 7.8068C4.91637 7.89208 5.21474 7.9065 5.50333 7.84878C6.34225 7.68102 6.94501 6.94259 6.94028 6.08708C6.93545 5.20513 7.05545 4.05585 7.56517 3.03646C8.04222 2.08234 8.69061 1.3825 9.26255 0.899927C9.89056 0.370054 10.7862 0.748133 11.1246 1.49686C11.7731 2.93137 13.0865 5.29883 15.5651 8.03642C16.8299 9.43334 17.9636 11.4049 17.9636 13.4349Z"  fill="currentColor"/>
    </svg>
  );
};

export default FireIcon;
