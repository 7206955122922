/* eslint-disable max-len */
export default function Youtube({ className }: { className?: string }) {
  return (
    <svg className={`${className}`} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.1917 8.40343C25.0588 7.9104 24.7991 7.46077 24.4385 7.09929C24.0778 6.73781 23.6288 6.4771 23.1361 6.34309C21.3091 5.84143 13.9999 5.83326 13.9999 5.83326C13.9999 5.83326 6.6919 5.82509 4.86373 6.30459C4.37131 6.44476 3.92319 6.70917 3.56239 7.07242C3.20159 7.43567 2.94023 7.88557 2.8034 8.37893C2.32156 10.2059 2.3169 13.9953 2.3169 13.9953C2.3169 13.9953 2.31223 17.8033 2.79056 19.6116C3.0589 20.6114 3.8464 21.4013 4.8474 21.6708C6.69306 22.1724 13.9824 22.1806 13.9824 22.1806C13.9824 22.1806 21.2916 22.1888 23.1186 21.7104C23.6115 21.5767 24.0609 21.3165 24.4225 20.9558C24.784 20.5951 25.0452 20.1462 25.1801 19.6536C25.6631 17.8278 25.6666 14.0396 25.6666 14.0396C25.6666 14.0396 25.6899 10.2304 25.1917 8.40343ZM11.6619 17.5058L11.6677 10.5058L17.7426 14.0116L11.6619 17.5058Z" fill="currentColor"/>
    </svg>
    
    // <svg
    //   className={`${className}`}
    //   width="32"
    //   height="33"
    //   viewBox="0 0 32 33"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M13.9789 14.3862C13.9789 14.0988 14.29 13.9192 14.5389 14.0629L18.5911 16.4024C18.8399 16.5461 18.8399 16.9054 18.5911 17.0491L14.5389 19.3886C14.29 19.5323 13.9789 19.3527 13.9789 19.0653V14.3862ZM17.5511 16.7257L14.7789 18.3263V15.1252L17.5511 16.7257Z"
    //     fill="currentColor"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M23.21 23.3914C24.6073 23.2699 25.7427 22.2103 25.9003 20.8166C26.0529 19.4667 26.1334 18.0647 26.1334 16.6264C26.1334 15.1881 26.0529 13.7861 25.9003 12.4362C25.7427 11.0425 24.6073 9.98288 23.21 9.86146C20.9288 9.66325 18.508 9.55664 16 9.55664C13.4921 9.55664 11.0713 9.66324 8.79007 9.86146C7.39273 9.98287 6.25736 11.0425 6.09976 12.4362C5.94712 13.7861 5.8667 15.1881 5.8667 16.6264C5.8667 18.0647 5.94712 19.4667 6.09976 20.8166C6.25736 22.2103 7.39273 23.2699 8.79007 23.3914C11.0713 23.5896 13.4921 23.6962 16 23.6962C18.508 23.6962 20.9288 23.5896 23.21 23.3914ZM25.0667 16.6264C25.0667 18.0251 24.9885 19.3871 24.8404 20.6967C24.7424 21.563 24.0326 22.2492 23.1177 22.3287C20.8681 22.5242 18.4781 22.6295 16 22.6295C13.522 22.6295 11.132 22.5242 8.88241 22.3287C7.96748 22.2492 7.25763 21.563 7.15968 20.6968C7.01158 19.3871 6.93337 18.0251 6.93337 16.6264C6.93337 15.2278 7.01158 13.8658 7.15968 12.5561C7.25763 11.6898 7.96748 11.0036 8.88241 10.9241C11.132 10.7287 13.522 10.6233 16 10.6233C18.4781 10.6233 20.8681 10.7287 23.1177 10.9241C24.0326 11.0036 24.7424 11.6898 24.8404 12.5561C24.9885 13.8658 25.0667 15.2278 25.0667 16.6264Z"
    //     fill="currentColor"
    //   />
    // </svg>
  );
}
