/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import Close from '@opulous/web/src/components/Icons/Close';
import MenuIcon from '@opulous/web/src/components/Icons/menu';
import Opulous from '@opulous/web/src/components/Icons/opulous';
import OpulousLogo from '@opulous/web/src/components/Icons/opulousLogo';
import useMediaQueries from '@opulous/web/src/hooks/useMediaQueries';
import { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import HeaderMenus from './Menus';
import MobileMenus from './MobileMenus';
import ThemeSwitcher from './ThemeSwitcher';
import Modal from '@opulous/web/src/components/Modal';
import ModalSide from '@opulous/web/src/components/ModalSide';
import { MENU_ITEMS_V3, SIDE_MENU_V3 } from '@opulous/web/src/lib/constants';
import ChevronUp from '@opulous/web/src/components/Icons/chevronUp';
import { breakpoints } from '@opulous/web/src/utils/style';
import { useLocation } from 'react-router';
import WalletContext from '@opulous/web/src/context/context';
// import { myAlgo } from '@opulous/web/src/services/';
// import assetsService from '@opulous/web/src/services/assets';
import { Asset, WalletTypeEnum } from '@opulous/web/src/shared/types';
import * as Sentry from '@sentry/react';
import Notifications from '@opulous/web/src/components/layout/Header/Notifications';
import WalletDropDownMenu from '@opulous/web/src/components/layout/Header/WalletDropDownMenu';
import MyAlgoWarningDialog from '@opulous/web/src/components/shared/MyAlgoWarningDialog';
import { DialogConnectWallet } from '@opulous/web/src/components/Common/ConnectWallet/DialogConnectWallet';
import { OpulInfo } from '@opulous/web/src/components/layout/Header/components';
import { Button } from '@mui/material';
import { useFeatureFlags } from '@opulous/web/src/hooks/useFeatureFlags';
import InfoIcon from '@opulous/web/src/components/Icons/Info';

const menuItems = MENU_ITEMS_V3;
const sideMenuItem = SIDE_MENU_V3;

const HeaderV3: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const { mounted } = useMediaQueries();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);
  const [showMenuMFT, setShowMenuMFT] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(1440);

  // const [assetCount, setAssetCount] = useState<number>(0);
  // const [opulCount, setOpulCount] = useState<number>(0);
  const [shouldShowMyAlgoWarningDialog, setShouldShowMyAlgoWarningDialog] =
    useState(false);
  
  const {
    state: { wallet, timestamp: walletTimestamp, isWalletConnectDialogOpen },
    disconnectWallet,
    initPeraWallet,
    setIsWalletConnectDialogOpen
  } = useContext(WalletContext);
  const { isActive } = useFeatureFlags();

  const connectWallet = async (type: WalletTypeEnum) => {
    switch (type) {
      case WalletTypeEnum.myAlgoWallet:
        setShouldShowMyAlgoWarningDialog(true);
        setIsWalletConnectDialogOpen(false);
        return;
      case WalletTypeEnum.peraWallet:
        initPeraWallet();
        setIsWalletConnectDialogOpen(false);
        return;
    }
  };

  const handleConnect = async (type: WalletTypeEnum) => {
    try {
      await connectWallet(type);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  // const checkAvailableAssets = async () => {
  //   if (!wallet) {
  //     return;
  //   }

  //   let unclaimedAssets: Asset[] = [];
  //   try {
  //     unclaimedAssets =
  //       (await assetsService.fetchAllAssetsAvailableToClaim(wallet)) || [];
  //   } catch (ex) {
  //     Sentry.captureException(ex);
  //   }

  //   setAssetCount(unclaimedAssets.length);
  // };

  // const fetchOpulCount = async () => {
  //   if (!wallet) {
  //     return;
  //   }

  //   let opulsOwned = 0;
  //   try {
  //     opulsOwned = (await myAlgo.getOpulAssetCount(wallet)) || 0;
  //   } catch (ex) {
  //     Sentry.captureException(ex);
  //   }

  //   setOpulCount(opulsOwned);
  // };

  // useEffect(() => {
  //   checkAvailableAssets();
  //   fetchOpulCount();
  // }, [wallet, location, walletTimestamp]);

  const handleDisconnect = () => {
    disconnectWallet();
  };

  useEffect(() => {
    const resizeHandler = () => {
      setWindowWidth(window.innerWidth);
    };
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  useEffect(() => {
    if(windowWidth >= breakpoints.lg) {
      setShowMobileMenu(false)
    }
  }, [windowWidth]);

  const hideConnectBtnPath = [
    '/',
    '/buyopul',
    '/opultoken',
    '/about',
    '/apply',
    '/buymfts',
    '/earlyaccess',
    '/maintenance',
    '/ai',
  ];

  const isNoTheme = useMemo(() => {
    if(pathname === '/ai') return true
    return false
  }, [pathname])

  const isMftUrlActive = useMemo(() => {
    if(pathname === '/apply') return true
    return false
  }, [pathname])

  return (
    <>
      <header
        id="header-bar"
        className={`${
          pathname === '/ai'
            ? (showMobileMenu ? 'bg-[linear-gradient(90.41deg,_#00000085_0%,_#00000000_100%)] ' : 'bg-transparent')
            : 'bg-surfaces-012 dark:bg-[#2f323c] border-b border-solid border-surfaces-007 dark:border-[#3b3e48]'
        } z-[999] flex w-full sticky top-0 lg:relative`}
      >
        <div className="container flex gap-3 lg:gap-3 items-center justify-between mx-auto px-5 lg:px-4 py-[1.375rem] lg:py-5 max-w-[77rem] min-h-[5.25rem]">
          {/* start: hamburger & logo */}
          <div className="flex gap-3 items-center lg:min-w-[24.5rem]">
            {
              <button
                className="flex lg:hidden flex-shrink-0 h-8 w-8"
                onClick={() => {
                  setShowMobileMenu((val) => !val);
                }}
              >
                {
                  showMobileMenu ? 
                  <Close className={`${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'} h-full w-full`} />
                  :
                  <MenuIcon className={`h-full w-full ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`} />
                }
                </button>
            }
            <a href="https://opulous.org" className="flex gap-2 items-center">
              <OpulousLogo className={`flex ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`} />
              <Opulous className={`${(hideConnectBtnPath.includes(pathname)) ? 'flex' : 'hidden lg:flex'}  ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`} />
            </a>
          </div>

          {/* end: hamburger & logo */}

          {/* start: menu */}
          <div className="hidden lg:flex lg:flex-grow">
            <Suspense fallback={''}>
              <HeaderMenus />
            </Suspense>
          </div>
          {/* end: menu */}

          {/* start: tools */}
          <div className="flex gap-3 lg:gap-5 items-center lg:justify-end lg:min-w-[24.5rem]">
            {
              pathname !== '/ai' && isActive('darkTheme')
                ? <ThemeSwitcher />
                : <></>
            }
            {/* <UserWallet /> */}
            {/* {!!wallet && (
              <>
                <Notifications count={assetCount} />
                <OpulInfo amount={opulCount} />
              </>
            )} */}
            {wallet && (
              <WalletDropDownMenu
                onDisconnect={handleDisconnect}
                wallet={wallet}
              />
            )}
            {!wallet && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => setIsWalletConnectDialogOpen(true)}
                style={{ padding: '6px 16px 6px 16px',  fontWeight: 500 }}
                className="lg:!text-lg !border main-button"
              >
                Connect wallet
              </Button>
            )}

            <button
              className="hidden lg:flex flex-shrink-0 h-8 w-8"
              onClick={() => {
                setShowSideMenu(!showSideMenu);
              }}
            >
              {showSideMenu ? (
                <Close className={`${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'} h-full w-full`} />
              ) : (
                <MenuIcon className={`h-full w-full ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`} />
              )}
            </button>
          </div>
          {/* end: tools */}
        </div>

        {/* start: mobile menu */}
        {showMobileMenu ? <MobileMenus showMobileMenu={showMobileMenu} onCloseMenu={() => setShowMobileMenu(false)} /> : <></>}
        {/* end: mobile menu */}

        {/* start: bottom nav */}
        <div
          className={`
            fixed h-[4.0625rem] bottom-0 left-0 w-screen border-t-[0.0625rem]
            lg:hidden flex justify-center px-2 py-3
          ${isNoTheme ? 'bg-[linear-gradient(90.41deg,_#00000085_0%,_#00000000_100%)] !backdrop-blur-lg border-[0.5px] border-[#363738]' : 'border-surfaces-012 dark:border-dark-surfaces-012 bg-surfaces-009 dark:bg-dark-surfaces-009'} bottom-wrapper
            ${(showMenuMFT || showWalletConnectModal) && 'z-[999]'}
            ${mounted ? 'opacity-100' : 'opacity-0'}
            transition-all ease-in-out duration-500
          `}
        >
          <div
            className={`
              relative flex items-center 
              w-full justify-around
            `}
          >
            {menuItems(isNoTheme).map((item, key) => (
              key !== 3 ?
              <a
                key={'nav-item' + key}
                href={String(item.url)}
                onClick={() => {
                  setShowSideMenu(false);
                }}
                className='flex items-center justify-center w-[5.625rem]'
              >
                <button
                  className={`
                    flex flex-col justify-center items-center rounded-lg text-xs leading-[1.125rem] gap-[0.125rem]
                    ${
                      pathname !== item.url
                        ? isNoTheme ? 'text-white' : 'text-surfaces-005'
                        : isNoTheme ? 'text-secondary-002' : 'text-primary-001'
                    }
                  `}
                >
                  <div>{item.icon}</div>
                  <div>{item.name}</div>
                </button>
              </a>
              :
              <div
                key={'nav-item' + key}
                onClick={() => {
                  setShowMenuMFT(!showMenuMFT)
                  setShowWalletConnectModal(false)
                }}
                className='flex items-center justify-center w-[5.625rem]'
              >
                <button
                  className={`
                    flex flex-col justify-center items-center rounded-lg text-xs leading-[1.125rem] gap-[0.125rem]
                    ${
                      isMftUrlActive ?
                      'text-primary-001'
                      :
                      showMenuMFT 
                      ? isNoTheme ? 'text-secondary-002' : 'text-surfaces-003 dark:text-white' : 
                      isNoTheme ? 'text-white' : 'text-surfaces-005'}
                  `}
                >
                  <div>{item.icon}</div>
                  <div>{item.name}</div>
                </button>
              </div>
            ))}

          </div>
        </div>
        {/* end: bottom nav */}

        <ModalSide 
          show={showSideMenu}
          width='w-[34rem]'
          title='About'
          titleClassName = {`font-semibold leading-[2.1rem] lg:leading-[2.125rem] normal-case ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'} text-[1.25rem] lg:text-[1.75rem]`}
          onClose={() => setShowSideMenu(false)}
          slideAnimation={true}
          modalZindex='z-[40]'
          backdropZindex='z-[30]'
        >
          <div className="flex flex-col gap-5 lg:gap-6 mt-3 lg:mt-6 h-full overflow-y-auto overflow-x-hidden w-full max-w-[33rem] overscroll-y-contain">
            {
              sideMenuItem(isNoTheme).map((menu, key) => (
                <div className='flex flex-col gap-3' key={key}>
                  <div className={`font-semibold text-5 leading-[1.875rem] ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                    {menu.name}
                  </div>
                  <div className='flex flex-col gap-3'>
                    {
                      menu.name === 'Community' ?
                      <>
                        {
                          menu.items.slice(0, 3).map((item, i) => (
                            <a
                              href={item.url}
                              key={`header-menu-dropdown-${i}`}
                              onClick={() => setShowSideMenu(false)}
                              target={item.isOpenNewTab ? "_blank" : undefined}
                              rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                              className={`${pathname === item.url
                                ? isNoTheme
                                  ? 'bg-[#FFFFFF0D]'
                                  : 'bg-surfaces-011 dark:bg-[#2D2F38]'
                                : 'bg-transparent'} ${isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-surfaces-011 hover:dark:bg-[#2D2F38] border-[0.0938rem] border-surfaces-012 dark:border-dark-surfaces-012'} rounded-2xl flex items-center gap-3 p-4`}
                            >
                              <span className={`${isNoTheme ? 'bg-[#FFFFFF1A]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-12 rounded-[0.75rem] w-12`}>
                                {item.icon ? item.icon : <></>}
                              </span>
                              <span className="flex flex-col gap-[0.125rem]">
                                <span className={`flex font-medium leading-[1.6875rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                                  {item.name}
                                </span>
                                {item.text ? (
                                  <span className={`flex leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-005 dark:text-[#999daa]'} text-xs`}>
                                    {item.text}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </a>
                          ))
                        }
                        <div className="flex gap-2">
                          {
                            menu.items.slice(3, menu.items.length).map((item, i) => (
                              <a
                                href={item.url}
                                onClick={() => setShowSideMenu(false)}
                                key={`header-menu-dropdown-${i}`}
                                target={item.isOpenNewTab ? "_blank" : undefined}
                                rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                                className={`bg-transparent ${isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-surfaces-011 hover:dark:bg-[#2D2F38] border-[0.0938rem] border-surfaces-012 dark:border-dark-surfaces-012'} rounded-2xl flex items-center justify-center flex-shrink-0 w-[9.33rem] h-[3.75rem]`}
                              >
                                {item.icon ? item.icon : <></>}
                              </a>
                            ))
                          }
                        </div>
                        
                      </>
                      
                      :
                      menu.items.map((item, i) => (
                        <a
                          href={item.url}
                          key={`header-menu-dropdown-${i}`}
                          onClick={() => setShowSideMenu(false)}
                          target={item.isOpenNewTab ? "_blank" : undefined}
                          rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
                          className={`${pathname === item.url
                            ? isNoTheme
                              ? 'bg-[#FFFFFF0D]'
                              : 'bg-surfaces-011 dark:bg-[#2D2F38]'
                            : 'bg-transparent'} ${isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-surfaces-011 hover:dark:bg-[#2D2F38] border-[0.0938rem] border-surfaces-012 dark:border-dark-surfaces-012'} rounded-2xl flex items-center gap-3 p-4`}
                        >
                          <span className={`${isNoTheme ? 'bg-[#FFFFFF1A]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-12 rounded-[0.75rem] w-12`}>
                            {item.icon ? item.icon : <></>}
                          </span>
                          <span className="flex flex-col gap-[0.125rem]">
                            <span className={`flex font-medium leading-[1.6875rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                              {item.name}
                            </span>
                            {item.text ? (
                              <span className={`flex leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-005 dark:text-[#999daa]'} text-xs`}>
                                {item.text}
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        </a>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </ModalSide>

        {/* Modal Menu MFT */}
        <Modal
          width="w-full lg:w-[46.5rem] relative"
          bgModal={`${isNoTheme ? 'bg-[linear-gradient(90.41deg,_#00000085_0%,_#00000000_100%)] !backdrop-blur-lg' : "bg-white dark:bg-surfaces-001"}`}
          show={showMenuMFT}
          onClose={() => setShowMenuMFT(false)}
          padding={'pb-[4.5rem] p-4 lg:p-10'}
          title="MFTs"
          modalZindex='z-[50]'
          backdropZindex='z-[40]'
          bgOverlay={isNoTheme ? 'bg-[rgb(0,0,0)]/[.65]' : 'bg-[rgb(51,51,51)]/[.35] dark:bg-[rgb(0,0,0)]/[.65]'}
        >
          <div className='flex flex-col gap-3 my-3'>
            <div className="bg-secondary-009 dark:bg-dark-secondary-009 border border-solid border-secondary-007 dark:border-[#8c471e] flex items-center gap-2 p-3 rounded-xl w-full">
              <div className="flex flex-shrink-0 h-6 w-6">
                <InfoIcon className="h-full text-primary-001 w-full" />
              </div>
              <p className="flex font-normal leading-[1.125rem] text-primary-001 text-xs">
                <span>
                  We{`'`}re switching from Algorand to Arbitrum. Head over to our <a className="font-bold underline" href="/migration">migration page</a> and follow the instructions.</span>
              </p>
            </div>
            {menuItems(isNoTheme)[3].items.map((item, i) => (
              <a
                href={item.url}
                key={`header-menu-dropdown-${i}`}
                onClick={() => setShowMenuMFT(false)}
                className={`
                ${pathname === item.url
                  ? isNoTheme
                    ? 'bg-[#FFFFFF0D]'
                    : 'bg-surfaces-011 dark:bg-[#2D2F38]'
                  : 'bg-transparent'} ${isNoTheme ? 'hover:bg-[#FFFFFF0D]' : 'hover:bg-surfaces-011 hover:dark:bg-[#2D2F38]'} flex items-center gap-3`}
                target={item.isOpenNewTab ? "_blank" : undefined}
                rel={item.isOpenNewTab ? "noopener noreferrer" : undefined}
              >
                <span className={`${isNoTheme ? 'bg-[rgba(255,255,255,0.1)]' : 'bg-secondary-009 dark:bg-[#512c16]'} flex items-center justify-center flex-shrink-0 h-9 rounded-[0.75rem] w-9`}>
                  {item.icon ? item.icon : <></>}
                </span>
                <span className="flex flex-col gap-[0.125rem]">
                  <span className={`flex font-medium leading-[1.6875rem] text-sm ${isNoTheme ? 'text-white' : 'text-surfaces-003 dark:text-white'}`}>
                    {item.name}
                  </span>
                  {item.text ? (
                    <span className={`flex leading-[1.125rem] ${isNoTheme ? 'text-[#999daa]' : 'text-surfaces-005 dark:text-[#999daa]'} text-xs`}>
                      {item.text}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                <ChevronUp className="ms-auto h-6 w-6 rotate-90 text-surfaces-006" />
              </a>
            ))}
          </div> 
        </Modal>
        {/* end of Modal Menu MFT */}
      </header>

      <DialogConnectWallet
        onClick={() => setIsWalletConnectDialogOpen(false)}
        onClose={() => setIsWalletConnectDialogOpen(false)}
        onConnect={handleConnect}
        style={{
          opacity: isWalletConnectDialogOpen ? 1 : 0,
          pointerEvents: isWalletConnectDialogOpen ? 'all' : 'none',
        }}
      />
      <MyAlgoWarningDialog
        show={shouldShowMyAlgoWarningDialog}
        onClose={() => setShouldShowMyAlgoWarningDialog(false)}
      />
    </>
  );
};

export default HeaderV3;