import React, { ReactNode, useState } from 'react';
import { styled } from '@mui/system';
import Popover from '@mui/material/Popover';

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: 362,
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
    boxShadow: `0 1px 14px -4px ${theme.palette.grey[300]}`,
  },
}));

interface PopoverMenuProps {
  anchor: ReactNode;
  children: ReactNode;
  className?: string;
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({
  anchor,
  children,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div onClick={handleClick}>{anchor}</div>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={className}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transitionDuration={0}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div onClick={handleClose}>{children}</div>
      </StyledPopover>
    </>
  );
};

export default PopoverMenu;
