/* eslint-disable max-len */
export default function WalletIcon({ className }: { className?: string }) {
  return (
    <svg
      className={`${className}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6 10.0737V7.70338C25.6 6.39612 24.5236 5.33301 23.2 5.33301H7.6C5.6152 5.33301 4 6.92827 4 8.88856V23.1108C4 25.7194 6.1528 26.6663 7.6 26.6663H25.6C26.9236 26.6663 28 25.6032 28 24.296V12.4441C28 11.1369 26.9236 10.0737 25.6 10.0737ZM23.2 20.7404H20.8V15.9997H23.2V20.7404ZM7.6 10.0737C7.29102 10.0601 6.99929 9.92927 6.78553 9.7085C6.57178 9.48772 6.45248 9.19403 6.45248 8.88856C6.45248 8.5831 6.57178 8.2894 6.78553 8.06863C6.99929 7.84786 7.29102 7.71703 7.6 7.70338H23.2V10.0737H7.6Z"
        fill="currentColor"
      />
    </svg>
  );
}
