interface Props {
  className?: string;
}

export default function ExclamationIcon({ className }: Props) {
  return (
    <svg
      className={`${className}`}
      width="58"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="5.25 5.25 21.5 21.5"
    >
      <path
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12V16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20H16.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
