import { ReactElement, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import '@opulous/web/src/App.css';
import WalletProvider from '@opulous/web/src/context/WalletProvider';
import { FeatureFlagsProvider } from '@opulous/web/src/hooks/useFeatureFlags';
import Layout from '@opulous/web/src/components/layout/Layout';
import routes from '@opulous/web/src/routes';
import { ThemeProvider } from '@mui/material';
import { lightTheme, darkTheme } from './theme';
import ThemeColorModeContext, {
  ThemeMode,
} from './context/ThemeColorModeContext';

const THEME_LOCAL_STORAGE_KEY = 'theme_mode';
import { useLocation } from 'react-router';

function App(): ReactElement {
  const location = useLocation();
  const [themeMode, setThemeMode] = useState(
    window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY) === ThemeMode.DARK
      ? ThemeMode.DARK
      : ThemeMode.LIGHT,
  );
  const toggleTheme = () =>
    setThemeMode(m =>
      m === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT,
    );

  const theme = themeMode === ThemeMode.DARK ? darkTheme : lightTheme;

  useEffect(() => {
    window.localStorage.setItem(THEME_LOCAL_STORAGE_KEY, themeMode);

    function checkIsOpulousFinancesPages(pathName: string): boolean {
      return pathName.startsWith('/migration');
    }

    const bodyEl = document.querySelector('body');
    if (bodyEl) {
      if (!checkIsOpulousFinancesPages(location.pathname)) {
        bodyEl.style.backgroundColor = (theme.palette.grey as any)['A900'];
      }
    }

    if (themeMode) {
      const root = document?.getElementsByTagName('html')?.[0];

      if (themeMode === 'dark') {
        root.setAttribute('class', 'dark');
      } else {
        root.setAttribute('class', 'light');
      }
    }
  }, [themeMode]);

  return (
    <ThemeColorModeContext.Provider
      value={{ toggle: toggleTheme, mode: themeMode }}
    >
      <ThemeProvider theme={theme}>
        <FeatureFlagsProvider>
          <SnackbarProvider maxSnack={2}>
            <WalletProvider>
              <Routes>
                <Route path="/" element={<Layout />}>
                  {routes.map(route => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                </Route>
              </Routes>
            </WalletProvider>
          </SnackbarProvider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </ThemeColorModeContext.Provider>
  );
}

export default App;
